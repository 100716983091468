import React, { useEffect, useState } from "react";
import styles from "./ViewPoojaType.module.css";
import { userRequest } from "../../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";

function ViewMasterPooja() {
  const location = useLocation();
  const pooja = location.state && location.state.pooja;
  const navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/master/pandit")}>Master Pooja</span>{" "}
            <span className={styles.activePageStyle}>&gt; View Pooja</span>
          </p>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Select Category</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="poojaCategory"
                value={pooja?.poojaCategory?.category}
                className={styles.poojaTypeInput}
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Pooja Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={pooja?.poojaName} disabled />
            </div>
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Deity</p>
            </div>
            <div className={styles.fieldInput}>
            <input
                type="text"
                name="poojaDiety"
                value={pooja?.poojaDiety?.daivaName}
                className={styles.poojaTypeInput}
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Pooja Image</p>
            </div>
            <div className={styles.fieldInput}>
            <input
                type="text"
                name="poojaImage"
                value={pooja?.poojaImage}
                className={styles.poojaTypeInput}
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Pooja Description</p>
            </div>
            <div className={styles.fieldInput}>
              <textarea
                value={pooja.poojaDescription}
                placeholder="Enter"
              />
            </div>
          </div>
        </div>

        <div>
          <div>
            <label className={styles.poojaTypeLabel}>Add Ritual</label>
          </div>
          {pooja?.poojaRituals.map((ritual, index) => (
            <div
              key={index}
              className={styles.poojaTypeContainer}
              style={{ width: "31%" }}
            >
              <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={ritual.name}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
                {/* <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    value={ritual.quantity}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Unit</label>
                  <input
                    type="text"
                    name="unit"
                    value={ritual?.unit}
                    className={styles.poojaTypeInput}
                    disabled
                  />
              
                </div> */}
              </div>
            </div>
          ))}
        </div>

        <div>
          <label className={styles.poojaTypeLabel}>Add Pooja Item</label>
          {pooja?.poojaItems.map((item, index) => (
            <div key={index} className={styles.poojaTypeContainer}>
              <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={item.name}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    value={item.quantity}
                    className={styles.poojaTypeInput}
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Unit</label>
                  <input
                    type="text"
                    name="unit"
                    value={item?.unit}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <label className={styles.poojaTypeLabel}>Add Prasadam Items</label>
          {pooja?.prasadamItems.map((item, index) => (
            <div key={index} className={styles.poojaTypeContainer}>
              <div className={styles.poojaTypeInputGroup}>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={item.name}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    value={item.quantity}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
                <div className={styles.poojaTypeInputWrapper}>
                  <label className={styles.poojaTypeInputLabel}>Unit</label>
                  <input
                    type="text"
                    name="unit"
                    value={item?.unit}
                    className={styles.poojaTypeInput}
                    disabled
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.footer}>
          <button onClick={() => navigate("/master/pandit")}>Discard</button>

          <button onClick={() => navigate("/master/pandit")}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default ViewMasterPooja;
