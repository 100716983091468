import React, { useEffect, useState } from "react";
import styles from "./createnotification.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";

export const userOptions = [
  { value: "Devotee", label: "Devotee" },
  { value: "Pandit", label: "Pandit" },
];

function CreateNotification() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("Devotee");
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sendTo, setSendTo] = useState("AllUser");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [uploadMedia, setUploadMedia] = useState("");
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  

  const handleUserSelectChange = (value) => {
    setSelectedUser(value.value);
  };

  const handleSendToChange = (value) => {
    setSendTo(value);
  };

  useEffect(() => {
    fetchData();
  }, [selectedUser, searchQuery]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/userManagement/getUserManagementList?userType=${selectedUser}&search=${searchQuery}`
      );
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (userId) => {
    const index = selectedUsersIds.indexOf(userId);
    if (index === -1) {
      setSelectedUsersIds([...selectedUsersIds, userId]);
    } else {
      const updatedSelectedUsersIds = [...selectedUsersIds];
      updatedSelectedUsersIds.splice(index, 1);
      setSelectedUsersIds(updatedSelectedUsersIds);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!title.trim()) {
      toast.error("Title is required");
      isValid = false;
    }

    if (!message.trim()) {
      toast.error("Message is required");
      isValid = false;
    }

    if (!uploadMedia) {
      toast.error("Media upload is required");
      isValid = false;
    }

    if (sendTo === "Individual" && selectedUsersIds.length === 0) {
      toast.error("At least one user must be selected");
      isValid = false;
    }

    if (!sendTo) {
      toast.error("Send To selection is required");
      isValid = false;
    }

    return isValid;
  };



  const handleSave = async () => {
    if (!validateForm()) {
      return; 
    }
    try {
      await userRequest.post("/pushNotification/createNotification", {
        sendTo: sendTo,
        title: title,
        message: message,
        uploadMedia: uploadMedia,
        userId: selectedUsersIds,
      });
      toast.success("Notification created successfully");
      navigate("/notificationmanagement");
    } catch (error) {
      console.error("Error creating notification:", error);
      // Show error message
      toast.error("Failed to create notification");
    }
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Notification Management", path: "/notificationmanagement" },
          { label: "Create Banner", path: "#" },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/notificationmanagement")}
        onSaveClick={handleSave}
      />
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Add Title</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Write Message</p>
            </div>
            <div className={styles.fieldInput}>
              <textarea onChange={(e) => setMessage(e.target.value)} />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, url) => setUploadMedia(url)}
                fieldName="uploadMedia"
              />
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Send To</p>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllUser"
                checked={sendTo === "AllUser"}
                onChange={() => handleSendToChange("AllUser")}
              />
              <label htmlFor="AllUser">All User</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllPandit"
                checked={sendTo === "AllPandit"}
                onChange={() => handleSendToChange("AllPandit")}
              />
              <label htmlFor="AllPandit">All Pandit</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="AllDevotee"
                checked={sendTo === "AllDevotee"}
                onChange={() => handleSendToChange("AllDevotee")}
              />
              <label htmlFor="AllDevotee">All Devotee</label>
            </div>
            <div className={styles.fieldRadioInput}>
              <input
                type="radio"
                id="Individual"
                checked={sendTo === "Individual"}
                onChange={() => handleSendToChange("Individual")}
              />
              <label htmlFor="Individual">Individual</label>
            </div>
          </div>
          {sendTo === "Individual" && (
            <div className={styles.fieldUserContainer}>
              <SelectComponent
                options={userOptions}
                onSelect={handleUserSelectChange}
                //  initialSelectedOption={formData?.taxType}
              />
              <div className={styles.userList}>
                <input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className={styles.notificationTable}>
                  <table>
                    <tbody>
                      {userData && userData.length > 0 ? (
                        userData.map((user, index) => (
                          <tr key={user._id}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(user._id)}
                              />
                            </td>
                            <td>{user.role}</td>
                            <td>{user.gender}</td>
                            <td>{user.city}</td>
                            <td>
                              {user.firstName} {user.lastName}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateNotification;
