import React, { useEffect, useState } from "react";
import styles from "./tasks.module.css";
import { Spin } from "antd";
import EditTask from "./EditTask/EditTask.jsx";
import { userRequest } from "../../../../requestMethod.js";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import ViewTask from "./ViewTask/ViewTask.jsx";




function Tasks() {
  const [taskData, setTaskData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getMasterTaskType?search=${searchQuery}&page=${page}`
      );
      setTaskData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    console.log("Export clicked");
  };

  return (
    <div className={styles.main}>
      {/* <div className={styles.pageHeader}>
        <p className={styles.taskHeader}>
          <span>Task Management</span>
        </p>
      </div> */}

      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <Spin spinning={loading} size="small">
            <div className={styles.tableContainerTask}>
              <table>
                <thead>
                  <tr>
                    <th>Task</th>
                    <th>Description</th>
                    <th>Task Type</th>
                    <th>API Endpoint</th>
                    <th>Method</th>
                    <th>Screen Flow</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {taskData.length > 0 ? (
                    taskData.map((task, index) => (
                      <tr key={task?._id}>
                        <td>{task?.title}</td>
                        <td>{task?.description}</td>
                        <td>{task?.taskType}</td>
                        <td>{task?.apiEndpoint}</td>
                        <td>{task?.method}</td>
                        <td>{task?.screenFlow}</td>
                        <td className={styles.actionTd}>
                          <EditTask fetchData={fetchData} item={task} />
                          <ViewTask fetchData={fetchData} item={task} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {taskData.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{currentPage}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default Tasks;

