import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./pageHeaderTwo.module.css";

const PageHeaderTwo = ({
  breadcrumb=[],
  discardLabel,
  saveLabel,
  onDiscardClick,
  onSaveClick,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.pageHeaderTwo}>
      <div className={styles.pageHeaderTwoLeft}>
        <p className={styles.pageHeaderTwoBreadcrumb}>
          {breadcrumb.map((item, index) => (
            <React.Fragment key={index}>
              <span 
                key={index}
                onClick={() => navigate(item.path)}
                className={index === breadcrumb.length - 1 ? styles.activePageStyle : ''}
              >
                {item.label}
              </span>
              {index < breadcrumb.length - 1 && ' > '}
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className={styles.pageHeaderTwoRight}>
        {discardLabel && (
          <button
            className={styles.btnSaveDiscard}
            onClick={onDiscardClick}
          >
            {discardLabel}
          </button>
        )}
        {saveLabel && (
          <button
            className={styles.btnSaveDiscard}
            onClick={onSaveClick}
          >
            {saveLabel}
          </button>
        )}
      </div>
    </div>
  );
};

PageHeaderTwo.propTypes = {
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  discardLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  onDiscardClick: PropTypes.func,
  onSaveClick: PropTypes.func,
};

PageHeaderTwo.defaultProps = {
  title: '',
  discardLabel: 'Discard',
  saveLabel: 'Save',
  onDiscardClick: () => {},
  onSaveClick: () => {},
};

export default PageHeaderTwo;
