import React, { useEffect, useState } from "react";
import styles from "../payoutDetails.module.css";
import { userRequest } from "../../../../requestMethod";
import { getConfig } from "../../../../utils/getConfig";

function History({payId}) {
  const { BASE_URL } = getConfig();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const response = await userRequest.get(
          `payout/getSvarnaPaymentHistory?payId=${payId}`
        );
        setPaymentHistory(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
        setCurrentPage(response.data.pagination.page);
      } catch (error) {
        console.error("Error fetching payment history:", error);
      }
    };

    fetchPaymentHistory();
  }, [page]);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
    <div className={styles.payoutTableContainer}>
      <table>
        <thead>
          <tr>
            <th>Approval Date</th>
            <th>Recipient Name</th>
            <th>Payout Method</th>
            <th>Paid Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paymentHistory.length > 0 ? (
            paymentHistory.map((item, index) => (
              <tr key={index}>
                <td>{formatDate(item?.added_on)}</td>
                <td>{item?.account_holder_name}</td>
                <td>{item?.transfer_mode}</td>
                <td>{item?.transfer_amount}</td>
                <td>{item?.status}</td>
                <td>
                  <span className={styles.viewDetails}>
                    <a
                      href={`${BASE_URL}/payout/exportSvarnaPaymentPdf/${item?._id}`}
                      target="_parent"
                    >
                      Export
                    </a>
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    {paymentHistory.length > 0 ? (
          <div className={styles.footer}>
            <div
              className={styles.action}
              style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
            >
              <button
                className={`${styles.actionButton} ${
                  page === 1 ? styles.disabled : styles.activeButton
                }`}
                onClick={handlePrevious}
                disabled={page === 1}
              >
                Previous
              </button>
              <button
                className={`${styles.actionButton} ${
                  page === totalPages ? styles.disabled : styles.activeButton
                }`}
                onClick={handleNext}
                disabled={page === totalPages}
              >
                Next
              </button>
            </div>
            <div className={styles.page}>
              <span>{currentPage}</span> <span>of</span>{" "}
              <span>{totalPages}</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
    </>
  );
}

export default History;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
