import React from "react";
import styles from "./styles.module.css";

function SearchFilterComponent({
  setSearchQuery,
  onExportClick,
  addComponent: AddComponent,
  onFilterClick,
  filter,
  label
}) {
  return (
    <div className={styles.filterExport}>
      <p>{label ?? "All"}</p>
      <div className={styles.search}>
        <input
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
        />
      </div>
      <div className={styles.export}>
        <p onClick={onExportClick}>Export as CSV, PDF, Excel doc</p>
      </div>
      {filter ? (
        <div className={styles.filterDiv}>
          <p onClick={onFilterClick}>Filter</p>
        </div>
      ) : (
        ""
      )}
      {AddComponent ? (
        <div className={styles.pageHeaderButton}>
          <AddComponent />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SearchFilterComponent;
