import React, { useEffect, useState } from "react";
import styles from "../CreateRoles/CreateRoles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function ViewRoles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [item, setItem] = useState([]);

  useEffect(() => {
    // Fetch item data from location state when component mounts
    if (location.state && location.state.item) {
      setItem(location.state.item);
    }
  }, [location.state]);

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          {
            label: "Roles and Permissions Management",
            path: "/rolesandpermissions",
          },
          { label: "Details", path: `#` },
        ]}
        discardLabel={"Back"}
        saveLabel={"Edit"}
        onDiscardClick={() => navigate("/rolesandpermissions")}
        onSaveClick={() =>
          navigate(
            `/rolesandpermissions/editroles/${location?.state?.item?._id}`,
            { state: { item } }
          )
        }
      />
      <div className={styles.contentContainer}>
        <h4>Basics Details</h4>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.name}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.email}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.password}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.roleId?.roleName}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.designation}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={item?.userId?.password}
                placeholder="Enter"
                disabled
              />
            </div>
          </div>
        </div>
        <div className={styles.permissionDiv}>
          <h5>Pemissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
                {item?.permissions?.map((permission) => (
                  <>
                    <tr className={styles.firstRow}>
                      <td>
                        <div className={styles.permissionName}>
                          <input type="checkbox" checked={permission?._id} />
                          <p className={styles.permissionNamePara}>
                            {permission?.permissionsId?.permissionName}
                          </p>
                        </div>
                      </td>
                      {/* <td>
      <div className={styles.editDiv}>
        <input
          type="checkbox"
          checked={permission?._id && permission?.edit}
        />
        <p className={styles.permissionNamePara}>Edit</p>
        </div>
      </td>
      <td>
      <div className={styles.editDiv}>
        <input
          type="checkbox"
          checked={permission?._id && permission?.view}
        />
        <p className={styles.permissionNamePara}>Only View</p>
        </div>
      </td> */}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRoles;
