import React, { useState } from 'react';
import styles from './menuItems.module.css';

const MenuItems = ({ menuItems, contentMap }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0].state);

  const handleMenuClick = (state) => {
    setActiveMenuItem(state);
  };
  
  return (
    <div className={styles.contentContainer}>
      <div className={styles.menuContainer}>
        {menuItems.map((item) => (
          <div
            key={item.state}
            className={
              activeMenuItem === item.state
                ? `${styles.singleMenu} ${styles.activeMenu}`
                : styles.singleMenu
            }
            onClick={() => handleMenuClick(item.state)}
          >
            <p>{item.label}</p>
          </div>
        ))}
      </div>
      <div className={styles.activePage}>
        {contentMap[activeMenuItem]}
      </div>
    </div>
  );
};

export default MenuItems;
