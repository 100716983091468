import React from "react";
import ViewItem from "../../../../../components/Modals/ViewItem";

const ViewLanguage = ({ fetchData, item }) => {
  const initialFormData = {
    language: item?.language,
  };

  const apiEndpoint = "/master/getMasterLanguage";
  const formFields = [{ name: "language", label: "Language", type: "text" }];
  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Language"
      item={item}
    />
  );
};

export default ViewLanguage;
