import React from "react";
import { Popconfirm } from "antd";
import deleteIcon from "../../assets/images/deleteIcon.png";

const DeleteAction = ({ item, onDelete }) => (
  <Popconfirm
    title={`Are you sure to delete?`}
    onConfirm={() => onDelete(item?._id)}
    okText="Yes"
    cancelText="No"
  >
    <img src={deleteIcon} alt="Delete" />
  </Popconfirm>
);

export default DeleteAction;
