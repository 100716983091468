import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./AddIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import { userRequest } from "../../../../../requestMethod";

const AddFacilities = ({fetchData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    subText: "",
    icon: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    if (!formData.name.trim()) {
      toast.error("Name is required");
      return false;
    }
    if (/\d/.test(formData.name)) {
      toast.error("Name cannot contain numbers");
      return false;
    }
    if (!formData.icon) {
      toast.error("icon is required");
      return false;
    }
    if (!formData.subText.trim()) {
      toast.error("subText is required");
      return false;
    }
    
    return true;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
     await userRequest.post(`/master/createAdminMasterFacilities`, formData);
    
      toast.success("Facilites added successfully");
      await fetchData()
      setFormData({
        name: "",
        subText: "",
        icon: "",
      })
    } catch (error) {
      console.log("Error creating Facilites:", error);
      // Show error message
      toast.error("Failed to create Facilites");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <button className={styles.addNewBtn} onClick={showModal}>Add New</button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
     
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Facilites</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.startDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Sub Text</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="subText"
                value={formData.subText}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, icon: file })
                }
                fieldName="uploadMedia"
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddFacilities;
