import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditPoojaCategory = ({ fetchData, item }) => {
  const initialFormData = {
    category: item?.category,
  };

  const apiEndpoint = "/master/updatePoojaCategory";
  const formFields = [{ name: "category", label: "Category", type: "text" }];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Pooja Category"
      item={item}
    />
  );
};

export default EditPoojaCategory;
