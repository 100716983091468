import React from "react";
import ViewItem from "../../../../../components/Modals/ViewItem";


const ViewCountries = ({ item,fetchData }) => {

  const initialFormData = {
    countryId: item?.countryId,
    country: item?.country,
    continent: item?.continent,
  };


  const formFields = [
    { name: "countryId", label: "Country Id", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
    { name: "continent", label: "Continent Name", type: "text" },
  ];

  return (
    <ViewItem
      formFields={formFields}
      title="View Country"
      item={initialFormData}
    />
  );
};

export default ViewCountries;


