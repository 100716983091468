import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditSamhitaCategories = ({ fetchData, item }) => {
  const initialFormData = {
    category: item?.category,
  };

  const apiEndpoint = "/master/updateMasterSamhitaCategory";
  const formFields = [{ name: "category", label: "Category", type: "text" }];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Samhita Type"
      item={item}
    />
  );
};

export default EditSamhitaCategories;
