// import React from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./pageHeader.module.css";

// const PageHeader = ({
//   title,
//   subTitle,
//   buttonLabel,
//   onButtonClick,
//   titleRoute,
//   disabled
// }) => {
//   const navigate = useNavigate();

//   return (
//     <div className={styles.pageHeader}>
//       <div className={styles.pageHeaderLeft}>
//         <p className={styles.pageItemHeader}>
//           <span onClick={() => navigate(titleRoute)}>
//             {title}{" "}
//             {subTitle ? (
//               <span className={styles.subHeadingColor}>
//                 {">"} {subTitle}
//               </span>
//             ) : (
//               ""
//             )}
//           </span>
//         </p>
//       </div>
//       <div className={styles.pageHeaderRight}>
//         {buttonLabel ? (
//           <button className={styles.addNewBtn} onClick={onButtonClick} disabled={disabled}>
//             {buttonLabel}
//           </button>
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );
// };

// export default PageHeader;

import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./pageHeader.module.css";

const PageHeader = ({
  breadcrumbs = [],
  buttonLabel,
  onButtonClick,
  disabled,
  backgroundWhite
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.pageHeader} style={{background:backgroundWhite?"#fff":"#fcfcfc"}}>
      <div className={styles.pageHeaderLeft}>
        <p className={styles.breadcrumb}>
          {breadcrumbs.map((item, index) => (
            <React.Fragment key={index}>
              <span
                key={index}
                onClick={() => navigate(item.path)}
                className={
                  index === breadcrumbs.length - 1 ? styles.activePageStyle : ""
                }
              >
                {item.label}
              </span>
              {index < breadcrumbs.length - 1 && " > "}
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className={styles.pageHeaderRight}>
        {buttonLabel && (
          <button
            className={styles.addNewBtn}
            onClick={onButtonClick}
            disabled={disabled}
          >
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
