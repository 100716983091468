import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./AddIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import { userRequest } from "../../../../../requestMethod";

const AddIshtDhaiva = ({fetchData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialFormData = {
    daivaName: '',
    daivaPic: '',
    daivaSlogan:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [resetKey, setResetKey] = useState(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (!formData.daivaName.trim()) {
      toast.error("Daiva Name is required");
      return false;
    }
    if (/\d/.test(formData.daivaName)) {
      toast.error("Daiva Name cannot contain numbers");
      return false;
    }
    if (!formData.daivaPic) {
      toast.error("Daiva Pic is required");
      return false;
    }
    if (!formData.daivaSlogan.trim()) {
      toast.error("Daiva Slogan is required");
      return false;
    }
    return true;
  };
  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
     await userRequest.post(`/master/createDaiva`, formData);    
      toast.success("Daiva added successfully");
      resetForm();
      await fetchData()
    } catch (error) {
      console.log("Error creating Daiva:", error);
      // Show error message
      toast.error("Failed to create Daiva");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <>
    <button className={styles.addNewBtn} onClick={showModal}>Add New</button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
       
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Istha Dhaiva</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="daivaName"
                value={formData.daivaName}
                onChange={handleChange}
                key={`name-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Daiva Pic</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, daivaPic: file })
                }
                fieldName="uploadMedia"
                key={`daivaPic-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Daiva Slogan</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="daivaSlogan"
                value={formData.daivaSlogan}
                onChange={handleChange}
                key={`daivaSlogan-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddIshtDhaiva;
