// import React, { useEffect, useState } from "react";
// import { Modal } from "antd";
// import styles from "./editDevalayaType.module.css";
// import { toast } from "sonner";
// import editIcon from "../../../../../assets/images/edit.png";
// import { userRequest } from "../../../../../requestMethod";

// const EditDevalayaType = ({fetchData,data}) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [formData, setFormData] = useState({
//     type: data?.type,
//   });

//   useEffect(() => {
//     setFormData({
//       type: data?.type,
//     });
//   }, [data]);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   console.log(data)


//   const handleOk = async () => {
//     setIsModalOpen(false);
//     const { type } = formData;

//     if (!type.trim()) {
//       toast.error("Type is required and cannot be empty");
//       await fetchData()
//       setFormData({
//         type: data?.type,
//       })
//       return;
//     }

//     if (/\d/.test(type)) {
//       toast.error("Type should not contain numbers");
//       await fetchData()
//       setFormData({
//         type: data?.type,
//       })
//       return;
//     }
//     try {
//       await userRequest.put(`/master/updateMasterDevalayaType/${data?._id}`, formData);      
//       toast.success("Devalaya type updated successfully");
//       await fetchData()
//       setFormData({
//         type: data?.type,
//       })
//     } catch (error) {
//       console.log("Error updating Dhaiva:", error);
//       // Show error message
//       toast.error("Failed to update Dhaiva");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <img src={editIcon} alt="" onClick={showModal} />

//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
     
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Edit Devalaya Type</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Type</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="type"
//                 value={formData.type}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Update</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditDevalayaType;

import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditDevalayaType = ({ fetchData, item }) => {
  const initialFormData = {
    type: item?.type,
  };

  const apiEndpoint = "/master/updateMasterDevalayaType";
  const formFields = [{ name: "type", label: "Type", type: "text" }];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Devalaya Type"
      item={item}
    />
  );
};

export default EditDevalayaType;


