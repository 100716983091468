import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import RouterPage from "../../components/RouterPage";
import logo from "../../assets/images/logo.png";
import dashboard from "../../assets/images/dashboardIcon.png";
import dashboardWhite from "../../assets/images/dashboardIconWhite.png";
import roles from "../../assets/images/navbar/roles.png";
import rolesWhite from "../../assets/images/navbar/rolesWhite.png";
import panditProfile from "../../assets/images/navbar/panditProfile.png";
import panditProfileWhite from "../../assets/images/navbar/panditProfileWhite.png";
import panditKYC from "../../assets/images/navbar/panditKYC.png";

import panditKYCWhite from "../../assets/images/navbar/panditKYCWhite.png";
import user from "../../assets/images/navbar/user.png";
import userWhite from "../../assets/images/navbar/userWhite.png";
import svarna from "../../assets/images/navbar/svarna.png";
import svarnaWhite from "../../assets/images/navbar/svarnaWhite.png";
import payoutIcon from "../../assets/images/navbar/receipt.png";
import payoutWhiteIcon from "../../assets/images/navbar/receiptWhite.png";
import samhitaIcon from "../../assets/images/navbar/samhita.png";
import samhitaWhiteIcon from "../../assets/images/navbar/samhitaWhite.png";
import bannerIcon from "../../assets/images/navbar/banner.png";
import bannerWhiteIcon from "../../assets/images/navbar/bannerWhite.png";
import notificationIcon from "../../assets/images/navbar/notificartion.png";
import notificationWhiteIcon from "../../assets/images/navbar/notificationWhite.png";
import poojaIcon from "../../assets/images/navbar/PoojaManagement.png";
import poojaWhiteIcon from "../../assets/images/navbar/PoojaManagementWhite.png";
import panditProfileIcon from "../../assets/images/navbar/PanditProfileIcon.png";
import panditProfileWhiteIcon from "../../assets/images/navbar/PanditProfileWhiteIcon.png";
import logoutIcon from "../../assets/images/navbar/logout.png";
import masterData from "../../assets/images/navbar/masterData.png";
import masterDataWhite from "../../assets/images/navbar/masterDataWhite.png";
import dropdown from "../../assets/images/navbar/dropdownBlack.png";
import dropdownWhite from "../../assets/images/navbar/dropdownWhite.png";
import HeaderComp from "../../components/HeaderComponent/HeaderComp";
import { useSelector } from "react-redux";
import { getConfig } from "../../utils/getConfig";

function Navbar() {
  const { TOKEN_KEY,STORAGE_KEY } = getConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { permissions } = useSelector((state) => state.admin.adminLoginData);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };



  const handleLogout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(`persist:${STORAGE_KEY}`);
    navigate("/login");
  };

  function DynamicIcon(iconName) {
    switch (iconName) {
      case "roles":
        return roles;

      case "panditKYC":
        return panditKYC;

      case "childKYC":
        return panditKYC;

      case "user":
        return user;

      case "masterData":
        return masterData;

      case "svarna":
        return svarna;

      case "payoutIcon":
        return payoutIcon;

      case "samhitaIcon":
        return samhitaIcon;

      case "bannerIcon":
        return bannerIcon;

      case "notificationIcon":
        return notificationIcon;

      case "poojaIcon":
          return poojaIcon;
          case "panditProfileIcon":
            return panditProfileIcon;
      default:
        break;
    }
    return svarna;
  }

  function ActiveIcon(activeIcon) {
    switch (activeIcon) {
      case "rolesWhite":
        return rolesWhite;

      case "panditKYCWhite":
        return panditKYCWhite;

        case "childKYCWhite":
          return panditKYCWhite;

      case "userWhite":
        return userWhite;

      case "masterDataWhite":
        return masterDataWhite;

      case "svarnaWhite":
        return svarnaWhite;

      case "payoutWhiteIcon":
        return payoutWhiteIcon;

      case "samhitaWhiteIcon":
        return samhitaWhiteIcon;

      case "bannerWhiteIcon":
        return bannerWhiteIcon;

      case "notificationWhiteIcon":
        return notificationWhiteIcon;
      
      case "poojaWhiteIcon":
          return poojaWhiteIcon;
          case "panditProfileWhiteIcon":
            return panditProfileWhiteIcon;
    

      default:
        break;
    }
  }

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  return (
    <div className={styles.navbarContainer}>
      <div
        className={`${styles.navbarMain} ${
          isMobileMenuOpen ? styles.showMobileMenu : ""
        }`}
      >
        <div className={styles.navbarHeader}>
          <img src={logo} alt="" onClick={() => navigate("/")} />
          <span>Tej</span>
          <div className={styles.mobileMenuIcon} onClick={toggleMobileMenu}>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>
            <div className={styles.bar}></div>
          </div>
        </div>

        <div
          className={`${styles.menuItems} ${
            isMobileMenuOpen ? styles.showMobileMenu : styles.hideMobileMenu
          }`}
        >
          {permissions?.map((item) =>
            !item.permissionsId.subPermissions.length > 0 ? (
              <MenuItem
                key={item.permissionsId.id}
                to={item.permissionsId.redirectUrl}
                label={item.permissionsId.permissionName}
                icon={DynamicIcon(`${item?.permissionsId?.icon}`)}
                activeIcon={ActiveIcon(`${item?.permissionsId?.activeIcon}`)}
              />
            ) : (
              <SubMenu
                key={item.permissionsId.id}
                label={item.permissionsId.permissionName}
                items={item.permissionsId.subPermissions.map((subItem) => ({
                  ...subItem,
                  label: subItem.subPermissionName,
                  to: subItem.subRedirectUrl,
                }))}
                icon={logoutIcon}
                activeIcon={notificationIcon}
              />
            )
          )}
          <MenuItem
            to="/"
            label="Logout"
            icon={logoutIcon}
            activeIcon={logoutIcon}
            onClick={handleLogout}
          />
        </div>
      </div>
      <div
        className={`${styles.page} ${isMobileMenuOpen ? styles.hidePage : ""}`}
      >
        <HeaderComp />
        <RouterPage />
      </div>
    </div>
  );
}

function MenuItem({ to, label, icon, activeIcon, onClick }) {
  const location = useLocation();
  let isActive = location.pathname === to;
  if (!isActive && to !== "/") {
    isActive = location.pathname.startsWith(to);
  }

  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick(); // Invoke the onClick function if provided
    }
  };
  return (
    <div className={`${styles.menu} ${isActive ? styles.active : ""}`}>
      {to === "/" ? (
        <div onClick={handleClick} className={styles.logoutMenu}>
          <img src={icon} alt="" />

          <p>{label}</p>
        </div>
      ) : (
        <Link to={to}>
          <img src={isActive ? activeIcon : icon} alt="" />
          <p>{label}</p>
        </Link>
      )}
    </div>
  );
}

function SubMenu({ label, items }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!location.pathname.startsWith("/master")) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <div
      className={`${styles.subMenuMain} ${
        isOpen ? styles.subMenuMainOpen : ""
      }`}
    >
      <div className={styles.subMenu} onClick={toggleSubMenu}>
        {isOpen ? (
          <img src={masterDataWhite} alt="" />
        ) : (
          <img src={masterData} alt="" />
        )}
        <p className={isOpen ? styles.activeLabel : ""}>{label}</p>
        {isOpen ? (
          <img src={dropdownWhite} alt="" />
        ) : (
          <img src={dropdown} alt="" />
        )}
      </div>
      {isOpen && (
        <div className={styles.subMenuItems}>
          {items.map((item, index) => (
            <SubMenuItem key={index} to={item.to} label={item.label} />
          ))}
        </div>
      )}
    </div>
  );
}

function SubMenuItem({ to, label }) {
  const location = useLocation();
  let isActive = location.pathname === to;
  if (!isActive && to !== "/") {
    isActive = location.pathname.startsWith(to);
  }

  return (
    <div
      className={`${styles.subMenuSingle} ${
        isActive ? styles.subMenuItemsActive : ""
      }`}
    >
      <Link to={to}>
        <p>{label}</p>
      </Link>
    </div>
  );
}

export default Navbar;
