import React from "react";
import ViewItem from "../../../../../components/Modals/ViewItem";

const ViewNakshatram = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
  };

  const apiEndpoint = "/master/getMasterRaasi";
  const formFields = [{ name: "name", label: "Nakshatram Name", type: "text" }];

  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Nakshatram"
      item={item}
    />
  );
};

export default ViewNakshatram;

