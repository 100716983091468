import React from "react";
import { Spin } from "antd";
import styles from "./style.module.css";

const TableComponent = ({
  columns,
  data,
  loading,
  onDelete,
  onStatusChange,
  actions,
  currentPage,
  totalPages,
  onNext,
  onPrevious,
  customWidths = {},
}) => {
  return (
    <Spin spinning={loading} size="small">
      <div className={styles.tableCompContainer}>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  style={{
                    width: `${customWidths[column.key] || "auto"} !important`,
                  }}
                >
                  {column.title}
                </th>
              ))}
              {actions && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item?._id}>
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        width: `${customWidths[column.key] || "auto"} !important`,
                        maxWidth: `${customWidths[column.key]} !important`,
                      }}
                    >
                      {column.render ? (
                        column.render(item, index)
                      ) : column.image === true ? (
                        <img
                          src={item[column.key]}
                          alt={`pic for ${column.title}`}
                          className={styles.tableImage}
                        />
                      ) : (
                        item[column.key]
                      )}
                    </td>
                  ))}
                  {actions && (
                    <td
                      className={styles.actionTd}
                      style={{ width: `${customWidths.action || "auto"} !important` }}
                    >
                      {actions.map((ActionComponent, actionIndex) => (
                        <ActionComponent
                          key={actionIndex}
                          item={item}
                          onDelete={onDelete}
                          onStatusChange={onStatusChange}
                        />
                      ))}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + (actions ? 1 : 0)}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <div className={styles.footer}>
          <div
            className={styles.action}
            style={{
              visibility: totalPages === 1 ? "hidden" : "visible",
            }}
          >
            <button
              className={`${styles.actionButton} ${
                currentPage === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={onPrevious}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                currentPage === totalPages
                  ? styles.disabled
                  : styles.activeButton
              }`}
              onClick={onNext}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span> <span>{totalPages}</span>
          </div>
        </div>
      )}
    </Spin>
  );
};

export default TableComponent;
