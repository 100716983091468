import React from "react";
import AddItem from "../../../../../components/Modals/AddItem";

const AddState = ({ fetchData }) => {
  const initialFormData = {
    stateId: "",
    state: "",
    country: "",
  };

  const apiEndpoint = "/master/createMasterState";
  const formFields = [
    { name: "stateId", label: "State Id", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add State"
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default AddState;
