import React from "react";
import AddItem from "../../../../../components/Modals/AddItem";

const AddRelationship = ({ fetchData }) => {
  const initialFormData = {
    name: "",
  };

  const apiEndpoint = "/master/createMasterRelationship";
  const formFields = [{ name: "name", label: "Name", type: "text" }];


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Relationship"
    />
  );
};

export default AddRelationship;

