import React, { useEffect, useState } from "react";
import styles from "./CreateRoles.module.css";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";


function CreateRoles() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const initialFormData = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
    designation: "",
    permissions: [],
    mobile: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [permissions, setPermissions] = useState([]);

  // Fetch permissions on component mount
  useEffect(() => {
    async function fetchPermissions() {
      try {
        const response = await userRequest.get(
          "/permissions/getAllPermissions"
        );
        setPermissions(response.data.data);

        const adminRoles = await userRequest.get(
          "/adminRoles/getAllAdminRoles"
        );
        setRoles(adminRoles?.data?.data);
      } catch (error) {
        console.error("Error fetching permissions:", error.message);
        alert("Failed to fetch permissions. Please try again later.");
      }
    }
    fetchPermissions();
  }, []);

  const handleCheckboxChange = (permissionsId) => {
    const isPermissionChecked = formData.permissions.some(
      (p) => p.permissionsId === permissionsId
    );
    let updatedPermissions;

    if (isPermissionChecked) {
      // If permission is checked, remove it from the permissions list
      updatedPermissions = formData.permissions.filter(
        (p) => p.permissionsId !== permissionsId
      );
    } else {
      // If permission is not checked, add it to the permissions list
      updatedPermissions = [...formData.permissions, { permissionsId }];
    }

    setFormData({ ...formData, permissions: updatedPermissions });
  };

  const options = roles?.map((role) => ({
    value: role?._id,
    label: role?.roleName,
  }));

  const saveData = async () => {
    try {
      await userRequest.post("/adminUserPermission/createUserForAdmin", formData);
      setFormData(initialFormData);
      navigate("/rolesandpermissions")
      toast.success("Saved successfully!");
    } catch (error) {
      console.log("Error saving data:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Roles and Permissions Management", path: "/rolesandpermissions" },
          { label: "Add Role and Permissions", path: "/rolesandpermissions/createroles" },
        ]}
        discardLabel={"Discard"}
        saveLabel={"Save"}
        onDiscardClick={() => navigate("/rolesandpermissions")}
        onSaveClick={saveData}
      />
      <div className={styles.contentContainer}>
        <h4>Basics Details</h4>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              {/* <select
                value={formData?.roleId || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    roleId: e.target.value,
                  })
                }
              >
                {options.map((option) => (
                  <option   key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select> */}
              <SelectComponent
                    options={options}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                  
                    roleId: selectedOption.label,
                      })
                    }
                    initialSelectedOption={formData.roleId}
                  />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.designation}
                onChange={(e) =>
                  setFormData({ ...formData, designation: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>

        <div className={styles.permissionDiv}>
          <h5>Pemissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
                {permissions.map((permission) => (
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={formData.permissions.some(
                            (p) => p.permissionsId === permission._id
                          )}
                          onChange={() => handleCheckboxChange(permission._id)}
                        />
                        <p className={styles.permissionNamePara}>
                          {permission.permissionName}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRoles;
