import React, { useEffect, useState } from "react";
import styles from "./createSamhita.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";

function CreateSamhita() {
  const navigate = useNavigate();
  const initialFormData = {
    samhitaName: "",
    uploadMedia: "",
    samhitaType: "",
    uploadPdf: "",
    category: "",
    subCategory: "",
    languages: "",
    author: "",
    ishtDevta: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [daivas, setDaivas] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedDaivaName, setSelectedDaivaName] = useState('');



  const handleSaveClick = async () => {
    if (validateForm()) {
      try {
        await userRequest.post("/admin/samhita/createSamhita", formData);
        setFormData(initialFormData);
        navigate('/samhitamanagement');
        toast.success("Saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    }
  }

  const validateForm = () => {
    let isValid = true;    
    if (!formData.samhitaName) {
      toast.error("Samhita Name is required.");
      isValid = false;
    } 
    if (!formData.uploadMedia) {
      toast.error("Upload Media is required.");
      isValid = false;
    }

    if (!formData.samhitaType) {
      toast.error("Type is required.");
      isValid = false;
    }

    if (!formData.uploadPdf) {
      toast.error("Upload PDF is required.");
      isValid = false;
    }

    if (!formData.category) {
      toast.error("Category is required.");
      isValid = false;
    }

    if (!formData.subCategory) {
      toast.error("Subcategory is required.");
      isValid = false;
    }

    if (!formData.languages) {
      toast.error("Language is required.");
      isValid = false;
    }

    if (!formData.author) {
      toast.error("Author is required.");
      isValid = false;
    }

    if (!formData.ishtDevta) {
      toast.error("Ishta Devata is required.");
      isValid = false;
    }

    return isValid;
  };


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryResponse = await userRequest.get(`/master/getMasterSamhitaCategory`);
      const subCategoryResponse = await userRequest.get(`/master/getMasterSamhitaSubCategory`);
      const daivaResponse = await userRequest.get(`/master/getDaiva`);
      const languageResponse = await userRequest.get(`/master/getMasterLanguage`);
      setCategories(categoryResponse.data.data || []);
      setSubCategories(subCategoryResponse.data.data || []);
      setDaivas(daivaResponse.data.daiva || []);
      setLanguages(languageResponse.data.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const typeOptions = [
    { value: "EPUB", label: "EPUB" },
    { value: "PDF", label: "PDF" },
    { value: "MOBI", label: "MOBI" },
  ];

  const languageOptions = languages.map((language) => ({
    value: language._id,
    label: language.language,
  }));

  const categoryOptions = categories.map((category) => ({
    value: category._id,
    label: category.category,
  }));

  const subCategoryOptions = subCategories.map((subcategory) => ({
    value: subcategory.subCategory,
    label: subcategory.subCategory,
  }));

  const daivaOptions = daivas.map((daiva) => ({
    value: daiva._id,
    label: daiva.daivaName,
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'samhitaName' || name === 'author') {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(value)) {
        toast.error("Please enter only letters");
        return      
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleTypeChange = (selectedOption) => {
    setFormData({
      ...formData,
      samhitaType: selectedOption.value,
    });
  };
  const getSelectedLabel = (value, options) => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label : "Select";
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.createSamhitaPara}>
            <span onClick={() => navigate("/samhitamanagement")}>
              Samhita Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Create Samhita</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button className={styles.btnSaveDiscard} onClick={() => navigate("/samhitamanagement")}>
            Discard
          </button>
          <button className={styles.btnSaveDiscard} onClick={handleSaveClick}>Save</button>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.tableContainer}>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Samhita Name</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="samhitaName"
                    value={formData.samhitaName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Upload Media</p>
                </div>
                <div className={styles.fieldInput}>
                  <FileUpload
                    label="Upload Media"
                    onChange={(fieldName, file) =>
                      setFormData({ ...formData, uploadMedia: file })
                    }
                    fieldName="uploadMedia"
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Type</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={typeOptions}
                    onSelect={handleTypeChange}
                    initialSelectedOption={getSelectedLabel(formData?.samhitaType,typeOptions)}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Upload PDF</p>
                </div>
                <div className={styles.fieldInput}>
                  <FileUpload
                    label="Upload PDF"
                    onChange={(fieldName, file) =>
                      setFormData({ ...formData, uploadPdf: file })
                    }
                    fieldName="uploadPdf" 
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={categoryOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        category: selectedOption.value,
                      })
                    }
                    initialSelectedOption={getSelectedLabel(formData?.category,categoryOptions)}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Language</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={languageOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        languages: selectedOption.value,
                      })
                    }
                    initialSelectedOption={getSelectedLabel(formData?.languages,languageOptions)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Subcategory</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={subCategoryOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        subCategory: selectedOption.value,
                      })
                    }
                    initialSelectedOption={getSelectedLabel(formData?.subCategory,subCategoryOptions)}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Author</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="author"
                    value={formData.author}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Ishta Devata</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={daivaOptions}
                    onSelect={(selectedOption) => {
                      const selectedDaiva = daivas.find((daiva) => daiva._id === selectedOption.value);
                      setSelectedDaivaName(selectedDaiva ? selectedDaiva.daivaName : '');
                      setFormData({
                        ...formData,
                        ishtDevta: selectedOption.value,
                      });
                    }}
                    initialSelectedOption={selectedDaivaName}
                  />
                </div>
              </div>
            </div>
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSamhita;


