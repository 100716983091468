import { toast } from "sonner";
import { userRequest } from "../requestMethod";


export const handleExportDoc = async (endpoint, fileName) => {
  try {
    const exportResponse = await userRequest.get(endpoint, {
      responseType: "blob",
    });

    // Create a Blob object from the response data
    const blob = new Blob([exportResponse.data], {
      type: "application/octet-stream",
    });

    // Create a URL for the blob and initiate download
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName; // Set the file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error exporting data:", error);
    toast.error("Error exporting data. Please try again later.");
  }
};
