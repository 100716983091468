import React from "react";
import ViewItem from "../../../../../components/Modals/ViewItem";

const ViewRules = ({ fetchData, item }) => {
  const initialFormData = {
    category: item?.category,
  };

  const apiEndpoint = "/master/samhitatype";
  const formFields = [{ name: "category", label: "Category", type: "text" }];

  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Samhita"
      item={item}
    />
  );
};

export default ViewRules;
