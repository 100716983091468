import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";
import { userRequest } from "../../../../../requestMethod";

const EditPanditKyc = ({ item, fetchData }) => {
  const initialFormData = {
    type: item?.type?._id,
    question: item?.question,
  };

  const apiEndpoint = "/master/updateMasterPanditKyc";
  const formFields = [
    {
      name: "type",
      label: "Pandit Type",
      type: "select",
      optionsKey: "panditType",
      optionValueKey: "_id",
      optionLabelKey: "type",
    },
    { name: "question", label: "Question", type: "text" },
  ];

    const fetchPanditType = async () => {
    try {
      const response = await userRequest.get(`/master/getAllPanditType`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Pandit Kyc"
      item={item}
      // uploadFieldName="icon"
      fetchDataOptions={{
        panditType: fetchPanditType,
      }}
    />
  );
};

export default EditPanditKyc;
