import React from "react";
import AddItem from "../../../../../components/Modals/AddItem";

const AddCountries = ({ fetchData }) => {

  const initialFormData = {
    countryId: "",
    country:"",
    continent:""
  };

  const apiEndpoint = "/master/createMasterCountry";
  const formFields = [
    { name: "countryId", label: "Country Id", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
    { name: "continent", label: "Continent Name", type: "text" },
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add Country"
    />
  );
};

export default AddCountries;

