// import React, { useEffect, useState } from "react";
// import { Modal } from "antd";
// import styles from "./editUtsavAttraction.module.css";
// import { toast } from "sonner";
// import editIcon from "../../../../../assets/images/edit.png";
// import { userRequest } from "../../../../../requestMethod";

// const EditUtsavAttraction = ({ fetchData, item }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const initialFormData = {
//     mame: item?.name,
//   };
//   const [formData, setFormData] = useState(initialFormData);

//   useEffect(() => {
//     setFormData({
//       name: item?.name || "",
//     });
//   }, [item]);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };
//   const validateForm = () => {
//     if (!formData.name.trim()) {
//       toast.error("Name is required");
//       return false;
//     }
//     if (/\d/.test(formData.name)) {
//       toast.error("Name cannot contain numbers");
//       return false;
//     }
  
//        // Update formData with trimmed values before submitting
//        setFormData({
//         ...formData,
//         name: formData.name.trim(),
//       });
//     return true;
//   };
//   const handleOk = async () => {
//     if (!validateForm()) {
//       return;
//     }
//     setIsModalOpen(false);

//     try {
//       await userRequest.put(
//         `/master/updateMasterUtsavAttraction/${item?._id}`,
//         formData
//       );

//       toast.success("Utsav attraction updated successfully");
//       await fetchData();
//       setFormData({
//         name: item?.name,
//       });
//     } catch (error) {
//       console.log("Error updating utsav attraction :", error);
//       // Show error message
//       toast.error("Failed to update utsav attraction ");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <img src={editIcon} alt="" onClick={showModal} />

//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Edit Utsav Attraction</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Utsav Attraction</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>

//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Update</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditUtsavAttraction;

import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditUtsavAttraction = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
  };

  const apiEndpoint = "/master/updateMasterUtsavAttraction";
  const formFields = [{ name: "name", label: "Name", type: "text" }];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Utsav Attraction"
      item={item}
    />
  );
};

export default EditUtsavAttraction;