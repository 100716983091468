// import React, { useEffect, useState } from "react";
// import { Modal } from "antd";
// import styles from "./addFamilyMember.module.css";
// import { toast } from "sonner";
// import SelectComponent from "../../../components/SelectComponent";
// import FileUpload from "../../../components/FileUpload/FileUpload";
// import { userRequest } from "../../../requestMethod";

// const AddFamilyMember = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [userData, setUserData] = useState([]);
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     gender: "",
//     mobile: "",
//     email: "",
//     profilePic: "",
//   });

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const fetchData = async () => {
//     try {
//       let response = await userRequest.get(
//         `/userManagement/getUserManagementList?userType=ALL`
//       );

//       setUserData(response.data.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleOk = async () => {
//     if (
//       !formData.firstName ||
//       !formData.lastName ||
//       !formData.gender ||
//       !formData.mobile ||
//       !formData.email ||
//       !formData.profilePic
//     ) {
//       toast.error("Please fill in all fields.");
//       return;
//     }
//     setIsModalOpen(false);
//     try {
//       await userRequest.post(`/userManagement/createPandit`, formData);

//       toast.success("Pandit created successfully");
//       setFormData({
//         firstName: "",
//         lastName: "",
//         gender: "",
//         mobile: "",
//         email: "",
//         profilePic: "",
//       });
//     } catch (error) {
//       console.log("Error creating Pandit:", error);
//       // Show error message
//       toast.error("Failed to create Pandit");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const options = [
//     { value: "Son", label: "Son" },
//     { value: "Daughter", label: "Daughter" },
//     { value: "Father", label: "Father" },
//     { value: "Mother", label: "Mother" },
//     { value: "Brother", label: "Brother" },
//     { value: "Sister", label: "Sister" },
//     { value: "Grandfather", label: "Grandfather" },
//     { value: "Grandmother", label: "Grandmother" },
//     { value: "Uncle", label: "Uncle" },
//     { value: "Aunt", label: "Aunt" },
//     { value: "Nephew", label: "Nephew" },
//     { value: "Niece", label: "Niece" },
//     { value: "Cousin", label: "Cousin" },
//     { value: "Husband", label: "Husband" },
//     { value: "Wife", label: "Wife" },
//     { value: "Stepfather", label: "Stepfather" },
//     { value: "Stepmother", label: "Stepmother" },
//     { value: "Stepson", label: "Stepson" },
//     { value: "Stepdaughter", label: "Stepdaughter" },
//     { value: "Father-in-law", label: "Father-in-law" },
//     { value: "Mother-in-law", label: "Mother-in-law" },
//     { value: "Brother-in-law", label: "Brother-in-law" },
//     { value: "Sister-in-law", label: "Sister-in-law" },
//     { value: "Son-in-law", label: "Son-in-law" },
//     { value: "Daughter-in-law", label: "Daughter-in-law" },
//   ];

//   const handleRelationsipSelect = (selectedOption) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       relationship: selectedOption.value,
//     }));
//   };

//   return (
//     <>
//       <button onClick={showModal} />
//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         width={"29vw"}
//         height={"100dvh"}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Add Family Member</p>
//         </div>
//         <div className={styles.contentMain}>
//         <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Member Mobile No.</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 placeholder="pls add country code e.g. +91XXXXXXXXXX"
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Family Member Mobile No.</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="firstName"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 placeholder="pls add country code e.g. +91XXXXXXXXXX"
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Relationship</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <SelectComponent
//                 options={options}
//                 onSelect={handleRelationsipSelect}
//                 // initialSelectedOption={formData.userType}
//               />
//             </div>
//           </div>

//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Add</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default AddFamilyMember;





import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./addFamilyMember.module.css";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";


const AddFamilyMember = ({userId,fetchUserDetails}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const initialFormData = {
    userId,
    familyMemberNum: "",
    relationship: "",
  }
  const [formData, setFormData] = useState(initialFormData);
  

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOk = async () => {
    const { familyMemberNum, relationship } = formData;

    if (!familyMemberNum || !relationship) {
      toast.error("Please fill in all fields.");
      return;
    }

    setIsModalOpen(false);

    try {
      await userRequest.post(`/userManagement/addFamilyMembersByAdmin`, formData);
      toast.success("Family member added successfully");
      resetForm();
      fetchUserDetails()
    } catch (error) {
      console.log("Error adding family member:", error);
      toast.error("Failed to add family member");
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  const options = [
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Brother", label: "Brother" },
    { value: "Sister", label: "Sister" },
    { value: "Grandfather", label: "Grandfather" },
    { value: "Grandmother", label: "Grandmother" },
    { value: "Uncle", label: "Uncle" },
    { value: "Aunt", label: "Aunt" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "Cousin", label: "Cousin" },
    { value: "Husband", label: "Husband" },
    { value: "Wife", label: "Wife" },
    { value: "Stepfather", label: "Stepfather" },
    { value: "Stepmother", label: "Stepmother" },
    { value: "Stepson", label: "Stepson" },
    { value: "Stepdaughter", label: "Stepdaughter" },
    { value: "Father-in-law", label: "Father-in-law" },
    { value: "Mother-in-law", label: "Mother-in-law" },
    { value: "Brother-in-law", label: "Brother-in-law" },
    { value: "Sister-in-law", label: "Sister-in-law" },
    { value: "Son-in-law", label: "Son-in-law" },
    { value: "Daughter-in-law", label: "Daughter-in-law" },
  ];

  const handleRelationshipSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      relationship: selectedOption.value,
    }));
  };

  return (
    <>
      <button onClick={showModal}/>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        width={"29vw"}
        height={"100dvh"}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Family Member</p>
        </div>
        <div className={styles.contentMain}>
          {/* <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Member Mobile No.</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="memberNum"
                value={formData.memberNum}
                onChange={handleChange}
                placeholder="pls add country code e.g. +91XXXXXXXXXX"
              />
            </div>
          </div> */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Family Member Mobile No.</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="familyMemberNum"
                value={formData.familyMemberNum}
                onChange={handleChange}
                placeholder="pls add country code e.g. +91XXXXXXXXXX"
                key={`familyMemberNum-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Relationship</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                options={options}
                onSelect={handleRelationshipSelect}
                key={`relationship-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddFamilyMember;
