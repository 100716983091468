import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditState = ({ item,fetchData }) => {

    const initialFormData = {
    stateId: item?.stateId,
    state: item?.state,
    country: item?.country,
  };

  const apiEndpoint = "/master/updateMasterState";
  const formFields = [
    { name: "stateId", label: "State Id", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
  ];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="State"
      item={item}
      // uploadFieldName="icon"
      // fetchDataOptions={{
      //   languages: fetchLanguages,
      // }}
    />
  );
};

export default EditState;

