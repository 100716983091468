// import React, { useEffect, useState } from 'react';
// import { Modal } from 'antd';
// import { userRequest } from '../../requestMethod';
// import { toast } from 'sonner';
// import styles from './style.module.css'
// import SelectComponent from '../SelectComponent';
// import FileUpload from '../FileUpload/FileUpload';



// const AddItem = ({
//   fetchData,
//   apiEndpoint,
//   initialFormData,
//   formFields,
//   title,
//   uploadFieldName,
//   fetchDataOptions = {},
// }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [formData, setFormData] = useState(initialFormData);
//   const [dataOptions, setDataOptions] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const loadOptions = async () => {
//       setLoading(true);
//       setError('');
//       try {
//         const optionsPromises = Object.keys(fetchDataOptions).map(key =>
//           fetchDataOptions[key]()
//         );
//         const results = await Promise.all(optionsPromises);
//         const optionsData = results.reduce((acc, result, index) => {
//           const key = Object.keys(fetchDataOptions)[index];
//           acc[key] = result;
//           return acc;
//         }, {});

//         if (JSON.stringify(dataOptions) !== JSON.stringify(optionsData)) {
//           setDataOptions(optionsData);
//         }
//       } catch (error) {
//         setError('Error fetching data options');
//         console.error('Error fetching data options:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     loadOptions();
//   }, [fetchDataOptions]);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (file) => {
//     setFormData({ ...formData, [uploadFieldName]: file });
//   };

//   const handleOk = async () => {
//     setIsModalOpen(false);

//     try {
//       await userRequest.post(apiEndpoint, formData);
//       toast.success(`${title} added successfully`);
//       await fetchData();
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error(`Error adding ${title.toLowerCase()}:`, error);
//       toast.error(`Failed to add ${title.toLowerCase()}`);
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//     setFormData(initialFormData); 
//   };

//   const getSelectedOptionLabel = (field) => {
//     if (!dataOptions[field.optionsKey]) return '';
//     const selectedOption = dataOptions[field.optionsKey].find(
//       (option) => option[field.optionValueKey] === formData[field.name]
//     );
//     return selectedOption ? selectedOption[field.optionLabelKey] : '';
//   };


//   return (
//     <>
//       <button className={styles.addBtn} onClick={showModal}>
//         Add New
//       </button>

//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Add {title}</p>
//         </div>
//         <div className={styles.contentMain}>
//           {loading && <p>Loading options...</p>}
//           {error && <p className={styles.error}>{error}</p>}
//           {!loading && !error && formFields.map((field) => (
//             <div className={styles.fieldContainer} key={field.name}>
//               <div className={styles.fieldLabel}>
//                 <p>{field.label}</p>
//               </div>
//               <div className={styles.fieldInput}>
//                 {field.type === 'text' && (
//                   <input
//                     type="text"
//                     name={field.name}
//                     value={formData[field.name] || ''}
//                     onChange={handleChange}
//                   />
//                 )}
//                 {field.type === 'select' && dataOptions[field.optionsKey] && (
//                   <SelectComponent
//                     options={dataOptions[field.optionsKey].map((option) => ({
//                       value: option[field.optionValueKey],
//                       label: option[field.optionLabelKey],
//                     }))}
//                     onSelect={(selectedOption) =>{
//                       setFormData({
//                         ...formData,
//                         [field.name]: selectedOption.value,
//                       })
//                     }
              
//                     }
//                     initialSelectedOption={getSelectedOptionLabel(field)}
//                   />
//                 )}
//                 {field.type === 'file' && (
//                   <FileUpload
//                     label={field.uploadLabel}
//                     onChange={handleFileChange}
//                     fieldName={uploadFieldName}
//                   />
//                 )}
//               </div>
//             </div>
//           ))}
//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Add</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default AddItem;
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { userRequest } from '../../requestMethod';
import { toast } from 'sonner';
import styles from './style.module.css'; // Import your styles
import SelectComponent from '../SelectComponent';
import FileUpload from '../FileUpload/FileUpload';

const AddItem = ({
  fetchData,
  apiEndpoint,
  initialFormData,
  formFields,
  title,
  uploadFieldName,
  fetchDataOptions = {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [dataOptions, setDataOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const loadOptions = async () => {
      setLoading(true);
      setError('');
      try {
        const optionsPromises = Object.keys(fetchDataOptions).map(key =>
          fetchDataOptions[key]()
        );
        const results = await Promise.all(optionsPromises);
        const optionsData = results.reduce((acc, result, index) => {
          const key = Object.keys(fetchDataOptions)[index];
          acc[key] = result;
          return acc;
        }, {});

        if (JSON.stringify(dataOptions) !== JSON.stringify(optionsData)) {
          setDataOptions(optionsData);
        }
      } catch (error) {
        setError('Error fetching data options');
        console.error('Error fetching data options:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOptions();
  }, [fetchDataOptions]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: '' }); // Clear specific validation error on change
  };

  const handleFileChange = (file) => {
    setFormData({ ...formData, [uploadFieldName]: file });
    setValidationErrors({ ...validationErrors, [uploadFieldName]: '' }); // Clear file-specific validation error
  };

  const validateFormData = () => {
    let errors = {};
    formFields.forEach((field) => {
      const value = formData[field.name];
      if (!value || (typeof value === 'string' && value.trim() === '')) {
        errors[field.name] = `${field.label} is required and cannot be empty`;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleOk = async () => {
    // Trim all string values in formData
    const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];
      acc[key] = typeof value === 'string' ? value.trim() : value;
      return acc;
    }, {});
  
    setFormData(trimmedFormData);
  
    if (!validateFormData()) {
      toast.error('Required');
      return;
    }
  
    setIsModalOpen(false);
    try {
      await userRequest.post(apiEndpoint, trimmedFormData);
      toast.success(`${title} added successfully`);
      await fetchData();
      setFormData(initialFormData);
    } catch (error) {
      console.error(`Error adding ${title.toLowerCase()}:`, error);
      toast.error(`Failed to add ${title.toLowerCase()}`);
    }
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
    setValidationErrors({});
  };

  const getSelectedOptionLabel = (field) => {
    if (!dataOptions[field.optionsKey]) return '';
    const selectedOption = dataOptions[field.optionsKey].find(
      (option) => option[field.optionValueKey] === formData[field.name]
    );
    return selectedOption ? selectedOption[field.optionLabelKey] : '';
  };

  return (
    <>
      <button className={styles.addBtn} onClick={showModal}>
        Add New
      </button>

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add {title}</p>
        </div>
        <div className={styles.contentMain}>
          {loading && <p>Loading options...</p>}
          {error && <p className={styles.error}>{error}</p>}
          {!loading && !error && formFields.map((field) => (
            <div className={styles.fieldContainer} key={field.name}>
              <div className={styles.fieldLabel}>
                <p>{field.label}</p>
              </div>
              <div className={styles.fieldInput}>
                {field.type === 'text' && (
                  <input
                    type="text"
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleChange}
                  />
                )}
                {field.type === 'select' && dataOptions[field.optionsKey] && (
                  <SelectComponent
                    options={dataOptions[field.optionsKey].map((option) => ({
                      value: option[field.optionValueKey],
                      label: option[field.optionLabelKey],
                    }))}
                    onSelect={(selectedOption) => {
                      setFormData({
                        ...formData,
                        [field.name]: selectedOption.value,
                      });
                      setValidationErrors({
                        ...validationErrors,
                        [field.name]: '', // Clear the error when an option is selected
                      });
                    }}
                    initialSelectedOption={getSelectedOptionLabel(field)}
                  />
                )}
                {field.type === 'file' && (
                  <FileUpload
                    label={field.uploadLabel}
                    onChange={handleFileChange}
                    fieldName={uploadFieldName}
                  />
                )}
                {validationErrors[field.name] && (
                  <p className={styles.error}>{validationErrors[field.name]}</p>
                )}
              </div>
            </div>
          ))}
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddItem;

