import React from "react";
import AddItem from "../../../../../components/Modals/AddItem";

const AddDevalayaArchitecture = ({ fetchData }) => {
  const initialFormData = {
    type: "",
  };

  const apiEndpoint = "/master/createMasterDevalayaArchitecture";
  const formFields = [{ name: "type", label: "Type", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Devalaya Architecture"
    />
  );
};

export default AddDevalayaArchitecture;

