import React, { useEffect, useState } from "react";
import styles from "./poojaMaterial.module.css";
import { userRequest } from "../../../../requestMethod.js";
import { toast } from "sonner";
import AddPoojaMaterial from "./AddPoojaMaterial/AddPoojaMaterial.jsx";
import EditPoojaMaterial from "./EditPoojaMaterial/EditPoojaMaterial.jsx";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import useDebounce from "../../../../utils/useDebounce.js";
import TableComponent from "../../../../components/TableComponent/TableComponent.jsx";
import { handleExportDoc } from "../../../../utils/exportDoc.js";
import DeleteAction from "../../../../components/TableComponent/DeleteAction.jsx";
import StatusToggleAction from "../../../../components/TableComponent/StatusToggleAction.jsx";

function PoojaMaterial() {
  const [PanditTypeData, setPanditTypeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [page, debouncedSearchQuery]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getMasterPoojaMaterial?search=${debouncedSearchQuery}&page=${page}`
      );
      setPanditTypeData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await userRequest.put(`/master/updateMasterPoojaMaterial/${id}`, {
        deleted: true,
      });
      toast.success("Pooja Material deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error update:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      setLoading(true);
      const newActiveStatus = !activeStatus;
      alert(newActiveStatus)
      await userRequest.put(`/master/updateMasterPoojaMaterial/${id}`, {
        active: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    handleExportDoc("/master/exportMasterPoojaMaterial", "poojaMaterial.xlsx");
  };

  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    { title: "Name", key: "name" },
  ];

  const actions = [
    // ({ item }) => <ViewCity item={item} />,
    ({ item }) => <EditPoojaMaterial fetchData={fetchData} item={item} />,
    ({ item }) => <DeleteAction item={item} onDelete={handleDelete} />,
    ({ item }) => (
      <StatusToggleAction item={item} onStatusChange={handleChange} />
    ),
  ];

  const customWidths = {
    sno: "5%",
    type: "45%",
    action: "40%",
  };

  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddPoojaMaterial fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
        <TableComponent
          columns={columns}
          data={PanditTypeData}
          loading={loading}
          actions={actions}
          onDelete={handleDelete}
          onStatusChange={handleChange}
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={() => setPage(page + 1)}
          onPrevious={() => setPage(page - 1)}
          customWidths={customWidths}
        />
      </div>
    </div>
  );
}

export default PoojaMaterial;
