import React, { useState } from "react";
import styles from "./style.module.css";
import profile from "../../../../assets/images/profile.png";
import Form from "react-bootstrap/Form";
import SelectReason from "../Reason/SelectReason";
import SeeReason from "../Reason/SeeReason";

function LiveImageVideo({
  data,
  panditLivePhotoStatus,
  setPanditLivePhotoStatus,
  basicVideoStatus,
  setBasicVideoStatus,
  professionalVideoStatus,
  setProfessionalVideoStatus,
  selectedLivePhotoReason,
  setSelectedLivePhotoReason,
  selectedBasicVideoReason,
  setSelectedBasicVideoReason,
  selectedProfessionalReason,
  setSelectedProfessionalReason
}) {
  const panditPhotosAndVideos = data && data[0]?.panditPhotosAndVideos[0];
  const panditPhoto =
    panditPhotosAndVideos && panditPhotosAndVideos.panditPhoto;
  const basicProfileVideo =
    panditPhotosAndVideos &&
    panditPhotosAndVideos.videoProfiles &&
    panditPhotosAndVideos.videoProfiles.basicProfileVideo;
  const professionalVideoProfile =
    panditPhotosAndVideos &&
    panditPhotosAndVideos.videoProfiles &&
    panditPhotosAndVideos.videoProfiles.professionalVideoProfile;

  const [isLivePhotoModalOpen, setIsLivePhotoModalOpen] = useState(false);
  const [isBasicVideoModalOpen, setIsBasicVideoModalOpen] = useState(false);
  const [isProfessionalVideoModalOpen, setIsProfessionalVideoModalOpen] = useState(false);

  const handleLivePhotoStatusChange = (e) => {
    const selectedValue = e.target.value;
    setPanditLivePhotoStatus(selectedValue);
    if (selectedValue === "Rejected") {
      setSelectedLivePhotoReason("");
      setIsLivePhotoModalOpen(true); 
    }
  };
  const handleBasicVideoStatusChange = (e) => {
    const selectedValue = e.target.value;
    setBasicVideoStatus(selectedValue);
    if (selectedValue === "Rejected") {
      setSelectedBasicVideoReason("");
      setIsBasicVideoModalOpen(true); 
    }
  };
  const handleProfessionalVideoStatusChange = (e) => {
    const selectedValue = e.target.value;
    setProfessionalVideoStatus(selectedValue);
    if (selectedValue === "Rejected") {
      setSelectedProfessionalReason("");
      setIsProfessionalVideoModalOpen(true); 
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.detailContainer}>
        <div className={styles.detailsHeading}>
          <ul>
            <li>Pandit’s live photos</li>
          </ul>
        </div>
        <div className={styles.details}>
          <div className={styles.profileImage}>
            <div className={styles.profileImageCircle}>
              <img src={panditPhoto || profile} alt="" />
            </div>
          </div>

          <div className={styles.selectAction}>
            <div className={styles.seeReason}>
              {panditLivePhotoStatus === "Rejected" ? (
                <SeeReason reason={selectedLivePhotoReason} />
              ) : null}
            </div>
            <SelectReason
              selectedReason={selectedLivePhotoReason}
              setSelectedReason={setSelectedLivePhotoReason}
              setIsModalOpen={setIsLivePhotoModalOpen}
              isModalOpen={isLivePhotoModalOpen}
              setPanditDetailsStatus={setPanditLivePhotoStatus}
            />

            <Form.Select
              size="sm"
              value={panditLivePhotoStatus}
              onChange={handleLivePhotoStatusChange}
              style={{ width: "52%", height: "4.5vh", borderRadius: "8px" }}
              // disabled={professionalVideoStatus === "Verified" ? true : false}
            >
              <option value="">Select</option>
              <option value="Verified">Verified</option>
              <option value="Rejected">Reject</option>
            </Form.Select>
          </div>
        </div>
      </div>

      <div className={`${styles.detailContainer} ${styles.secondDetail}`}>
        <div className={styles.detailsHeading}>
          <ul>
            <li>Pandit’s video profile</li>
          </ul>
        </div>
        <div className={styles.videoContainer}>
          <div className={styles.videoSection}>
            <div className={styles.video}>
              <p>Basic Information</p>
              <video
                src={basicProfileVideo || ""}
                width={150}
                height={100}
                controls
              ></video>
            </div>
          </div>
          <div className={styles.selectAction}>
          <div className={styles.seeReason}>
              {basicVideoStatus === "Rejected" ? (
                <SeeReason reason={selectedBasicVideoReason} />
              ) : null}
            </div>
            <SelectReason
              selectedReason={selectedBasicVideoReason}
              setSelectedReason={setSelectedBasicVideoReason}
              setIsModalOpen={setIsBasicVideoModalOpen}
              isModalOpen={isBasicVideoModalOpen}
              setPanditDetailsStatus={setBasicVideoStatus}
            />
            <Form.Select
              size="sm"
              value={basicVideoStatus}
              onChange={handleBasicVideoStatusChange}
              style={{ width: "52%", height: "4.5vh", borderRadius: "8px" }}
              // disabled={basicVideoStatus === "Verified" ? true : false}
            >
              <option value="">Select</option>
              <option value="Verified">Verified</option>
              <option value="Rejected">Reject</option>
            </Form.Select>
          </div>
        </div>
        {/* <div className={styles.videoContainer}>
          <div className={styles.videoSection}>
            <div className={styles.video}>
              <p>Pandit Experience</p>
              <video
                src={professionalVideoProfile || ""}
                width={150}
                height={100}
                controls
              ></video>
            </div>
          </div>
          <div className={styles.selectAction}>
          <div className={styles.seeReason}>
              {professionalVideoStatus === "Rejected" ? (
                <SeeReason reason={selectedProfessionalReason} />
              ) : null}
            </div>
            <SelectReason
              selectedReason={selectedProfessionalReason}
              setSelectedReason={setSelectedProfessionalReason}
              setIsModalOpen={setIsProfessionalVideoModalOpen}
              isModalOpen={isProfessionalVideoModalOpen}
              setPanditDetailsStatus={setProfessionalVideoStatus}
            />
            <Form.Select
              size="sm"
              value={professionalVideoStatus}
              onChange={handleProfessionalVideoStatusChange}
              style={{ width: "52%", height: "4.5vh", borderRadius: "8px" }}
              // disabled={basicVideoStatus === "Verified" ? true : false}
            >
              <option value="">Select</option>
              <option value="Verified">Verified</option>
              <option value="Rejected">Reject</option>
            </Form.Select>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LiveImageVideo;
