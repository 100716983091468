export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const parseAndFormatDate = (dateString) => {
  try {
    // Extract date and time parts
    const [datePart, timePart, period] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    const [hours, minutes] = timePart.split(":").map(Number);

    // Convert hours based on AM/PM
    let adjustedHours = hours;
    if (period === "PM" && hours < 12) {
      adjustedHours = hours + 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0; // Midnight case
    }

    // Create a new date object
    const date = new Date(year, month - 1, day, adjustedHours, minutes);

    // Format the date to a readable string
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  } catch {
    return "Invalid Date";
  }
};
