import React from "react";
import styles from "./masterLanguage.module.css";
import Language from "./Language/Language";
import PageHeader from "../../../components/PageHeader/PageHeader";
import MenuItems from "../../../components/MenuItems/MenuItems";

function MasterLanguage() {
  const menuItems = [{ label: "Language", state: "Language" }];
  const contentMap = {
    Language: <Language />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Master Data", path: "/master/language" },
          { label: "Language", path: "/master/language" },
        ]}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default MasterLanguage;
