import React from "react";
import styles from "./PoojaBookingManagement.module.css";

import PoojaBooking from "./PoojaBooking/PoojaBooking";
import PageHeader from "../../components/PageHeader/PageHeader";
import MenuItems from "../../components/MenuItems/MenuItems";

function PoojaBookingManagement() {
  const menuItems = [
    { label: "Booked", state: "Booked" },
    { label: "Upcoming", state: "Upcoming" },
    { label: "Completed", state: "Completed" },
  ];
  const contentMap = {
    Booked: <PoojaBooking bookedType="Booked" />,
    Upcoming: <PoojaBooking bookedType="Upcoming" />,
    Completed: <PoojaBooking bookedType="Completed" />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Pooja Booking", path: "/poojabookingmanagement" },
        ]}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default PoojaBookingManagement;
