import React, { useEffect, useState } from "react";
import styles from "./details.module.css";
import { userRequest } from "../../../../requestMethod";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { Spin } from "antd";

function Details() {
  const { id } = useParams();

  const [payoutData, setPayoutData] = useState(null);

  const fetchPayoutData = async () => {
    try {
      const response = await userRequest.get(
        `/payout/getSinglePayoutData/${id}`
      );
      setPayoutData(response.data.data[0]); // Assuming response.data is an array with one object
    } catch (error) {
      console.error("Error fetching payout data:", error);
    }
  };

  useEffect(() => {
    fetchPayoutData();
  }, []);

  if (!payoutData) {
    return <Spin size="small" fullscreen></Spin>;
  }

  // Destructure payoutData to access the data
  const {
    payId,
    payoutAmt,
    receiptName,
    initiationDate,
    roleId,
    completionDate,
    campaignId,
    LevelID,
    status,
    stateOrRegion,
    country,
    levelAchieved,
    pointsEarned,
    note,
    paymentRefNo,
    paymentMethod,
    receiptContact,
    receiptAddress,
    paidBy,
  } = payoutData;

  console.log(roleId)
  console.log(paidBy)

  const handlePayClick = async (payId, payoutAmt, LevelID, campaignId) => {
    let payData = {
      transfer_amount: payoutAmt,
      beneficiary_details: {
        beneficiary_id: payId,
      },
    };
    try {
      const createTransferResponse = await userRequest.post(
        "/payout/createSvarnaPaymentTransfer",
        payData
      );
      const transferId = createTransferResponse.data.data.transfer_id;
      toast.success(
        "Payment transfer initiated. Checking status in 5 seconds..."
      );

      // Wait for 5 seconds before checking the status
      setTimeout(async () => {
        try {
          const getTransferStatusResponse = await userRequest.get(
            `/payout/getSvarnaPaymentTransferStatus/${transferId}?payId=${payId}&LevelID=${LevelID}&campaignId=${campaignId}`
          );
          toast.success(getTransferStatusResponse.data.message);
          // fetchData(); // Refresh data after getting status
        } catch (error) {
          toast.error("Error checking payment status");
        }
      }, 5000);
    } catch (error) {
      toast.error("Error processing payment");
    }
  };

  return (
    <div className={styles.subMain}>
      <div className={styles.detailsContainer}>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Payout Id</p>
            <p>{payId}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Payout Amount</p>
            <p>{payoutAmt}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Recipient Name</p>
            <p>{receiptName}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Initiation Date</p>
            <p>{formatDate(initiationDate)}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Role</p>
            <p>{roleId?.roleName}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Expected Date</p>
            <p>{formatDate(completionDate)}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Recipient Contact</p>
            <p>{receiptContact}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Completion Date</p>
            <p>{formatDate(completionDate)}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Campaign ID</p>
            <p>{campaignId}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Status</p>
            <p>{status}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Recipient Address</p>
            <p>{receiptAddress}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Payment Method</p>
            <p>{paymentMethod}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>State/Region</p>
            <p>{stateOrRegion}</p>
          </div>
          <div className={styles.singleDetail}>
            <p>Payment Reference No.</p>
            <p>{paymentRefNo}</p>
          </div>
        </div>
        <div className={styles.singleDetailContainer}>
          <div className={styles.singleDetail}>
            <p>Country</p>
            <p>{country}</p>
          </div>
          <div className={styles.singleDetail}></div>
        </div>
      </div>
      <div className={styles.achievementInfo}>
        <div className={styles.achievementContainer}>
          <div className={styles.singleAchievement}>
            <p>Level Achieved</p>
            <p>{levelAchieved}</p>
          </div>
          <div className={styles.singleAchievement}>
            <p>Points Earned</p>
            <p>{pointsEarned}</p>
          </div>
        </div>
        {paidBy ? (
          <div className={styles.paidBy}>
            <p>Paid By</p>
            <p>
              {paidBy?.name} <span>({paidBy?.roleId?.roleName})</span>
            </p>
          </div>
        ) : null}
        <div className={styles.note}>
          <p>Note</p>
          <p>{note}</p>
        </div>
      </div>
      <div className={styles.paymentButton}>
        <button
          className={
            status === "SUCCESS" ? styles.disabledPayButton : styles.payButton
          }
          onClick={() => handlePayClick(payId, payoutAmt, LevelID, campaignId)}
          disabled={status === "SUCCESS" || status === "PENDING"}
        >
          {status === "SUCCESS" ? "Paid" : "Pay"}
        </button>
      </div>
    </div>
  );
}

export default Details;

function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
