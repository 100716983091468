import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./editUtsavType.module.css";
import { toast } from "sonner";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";
import FileUpload from "../../../../../components/FileUpload/FileUpload";

const EditUtsavType = ({ fetchData, item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialFormData = {
    mame: item?.name,
    icon: item?.icon,
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData({
      name: item?.name || "",
      icon: item?.icon || "",
    });
  }, [item]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      toast.error("Name is required");
      return false;
    }
    if (/\d/.test(formData.name)) {
      toast.error("Name cannot contain numbers");
      return false;
    }
    if (!formData.icon) {
      toast.error("icon is required");
      return false;
    }
       // Update formData with trimmed values before submitting
       setFormData({
        ...formData,
        name: formData.name.trim(),
      });
    return true;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);
    try {
      await userRequest.put(
        `/master/updateMasterUtsavType/${item?._id}`,
        formData
      );

      toast.success("Utsav type updated successfully");
      await fetchData();
      setFormData({
        name: item?.name,
        icon: item?.icon,
      });
    } catch (error) {
      console.log("Error updating utsav type :", error);
      // Show error message
      toast.error("Failed to update utsav type ");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Utsav Type</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Utsav Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Uploaded Media</p>
            </div>
            <div className={styles.fieldInput}>
              <img
                src={formData?.icon}
                alt=""
                style={{ width: "15%", height: "15%" }}
              />
            </div>
          </div> */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Update Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, icon: file })
                }
                fieldName="uploadMedia"
                name={formData.icon}
              />
            </div>
          </div>

          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditUtsavType;
