import React from 'react'
import styles from './schoolingDetails.module.css'
import profile from '../../../../assets/images/profile.png'

function SchoolingDetails({data}) {
  // console.log(data)
  // const schoolingDetails = data[0]?.documents[0];
  console.log(data)
  const documents = data[0]?.documents[0]
  const schoolingDetails = data[0]?.schoolingDetails[0]
  const email = data[0]?.PanditDetails[0]?.email;
    return (
      <div className={styles.main}>
        <div className={styles.singleDetailContainer}>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Schooling Details</li>
            </ul>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Pandit Type</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{schoolingDetails?.panditType?.type}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Degree Type</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>Home</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Degree Name</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>Vaidika Vignanam</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Guru Name</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>Acharya pandit</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Address</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>Varanasi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Pandit's Documents</li>
            </ul>
          </div>
          <div className={styles.details} style={{paddingLeft:"5%"}}>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeftTwo}>
                  <p>Document issuing country/region</p>
                </div>
                <div className={styles.singleDetailRightTwo}>
                  <p>{documents?.issuingCountry}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeftTwo}>
                  <p>Aadhar Number ( Only Of Indian Citizens)</p>
                </div>
                <div className={styles.singleDetailRightTwo}>
                  <p>{documents?.aadharNumber}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeftTwo}>
                  <p>Passport</p>
                </div>
                <div className={styles.singleDetailRightTwo}>
                  <p>View Passport</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeftTwo}>
                  <p>Email</p>
                </div>
                <div className={styles.singleDetailRightTwo}>
                  <p>{email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      )
}

export default SchoolingDetails
