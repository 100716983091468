import React, { useEffect, useState } from "react";
import styles from "./masterPooja.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../../requestMethod";
import useDebounce from "../../../../utils/useDebounce";
import editIcon from "../../../../assets/images/edit.png";
import viewIcon from "../../../../assets/images/viewIcons.png";
import { handleExportDoc } from "../../../../utils/exportDoc";
import StatusToggleAction from "../../../../components/TableComponent/StatusToggleAction";
import DeleteAction from "../../../../components/TableComponent/DeleteAction";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent";
import TableComponent from "../../../../components/TableComponent/TableComponent";


function MasterPooja() {
  const navigate = useNavigate();
  const [tejPointsData, setTejPointsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, totalPages]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getAllPoojaForAdmin?search=${debouncedSearchQuery}&page=${page}`
      );
      setTejPointsData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (pooja) => {
    navigate(`/master/pandit/viewmasterpooja`, { state: { pooja } });
  };
  const handleEditClick = (pooja) => {
    navigate(`/master/pandit/editmasterpooja`, { state: { pooja } });
  };
  const handleDelete = async (id) => {
    try {
      await userRequest.put(`/master/updatePoojaById/${id}`, {
        deleted: true,
      });
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleChange = async (id, active) => {
    const newState = !active;
    try {
      await userRequest.put(`/master/updatePoojaById/${id}`, {
        active: newState,
      });
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleExport = () => {
    handleExportDoc("/master/exportPoojaToExcel", "MasterPooja.xlsx");
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Category",
      key: "category",
      render: (item) => item?.poojaCategory?.category,
    },
    { title: "Pooja Name", key: "poojaName" },
    {
      title: "Pooja Rituals",
      key: "poojaRituals",
      render: (item) =>
        item?.poojaRituals?.map((item) => item?.name).join(" , "),
    },
    {
      title: "Prasadam Items",
      key: "prasadamItems",
      render: (item) => item?.poojaItems?.map((item) => item?.name).join(" , "),
    },
  ];

  const actions = [
    ({ item }) => (
      <img
        src={viewIcon}
        alt="View"
        onClick={() => handleViewClick(item)}
        style={{ cursor: "pointer", marginRight: "12px" }}
      />
    ),
    ({ item }) => (
      <img
        src={editIcon}
        alt="Edit"
        onClick={() => handleEditClick(item)}
        style={{ cursor: "pointer", marginRight: "12px" }}
      />
    ),
    ({ item }) => <DeleteAction item={item} onDelete={handleDelete} />,
    ({ item }) => (
      <StatusToggleAction item={item} onStatusChange={handleChange} />
    ),
  ];

  const CreateNewPoojaButton = () => (
    <button onClick={() => navigate("/master/pandit/createmasterpooja")}>
      Add Pooja
    </button>
  );

  return (
    <div className={styles.tableMain}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={CreateNewPoojaButton}
      />
      <div className={styles.poojaTableContainer}>
        <TableComponent
          columns={columns}
          data={tejPointsData}
          loading={loading}
          actions={actions}
          currentPage={page}
          totalPages={totalPages}
          onNext={() => page < totalPages && setPage(page + 1)}
          onPrevious={() => page > 1 && setPage(page - 1)}
        />
      </div>
    </div>
  );
}

export default MasterPooja;
