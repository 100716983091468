import React, { useEffect, useState } from "react";
import styles from "./ViewPanditProfileDetails.module.css"; // Importing CSS module
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";
import googleMapIcon from "../../../assets/images/navbar/googleMapIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import devalayaPic from "../../../assets/images/devalaya.png";

const ViewPanditProfileDetails = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [poojaDetails, setPoojaDetails] = useState([]);
  const location = useLocation();

  const fetchPanditDetails = async () => {
    try {
      let id = location?.state?.id;
      const response = await userRequest.get(
        `/panditProfileManagement/getSinglePanditProfile/${id}`
      );

      let data = response?.data?.data;

      setPoojaDetails(data?.poojaDetails);
      setServices(data?.templeDetails);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  useEffect(() => {
    fetchPanditDetails();
  }, []);

  return (
    <>
      <PageHeaderTwo
        breadcrumb={[
          {
            label: "Pandit Profile Management",
            path: "/panditprofilemanagement",
          },
          { label: "Profile Details", path: `#` },
        ]}
        discardLabel={"Back"}
        onDiscardClick={() => navigate("/panditprofilemanagement")}
        saveLabel={false}
      />

      <div className={styles.profileDetails}>
        <div className={styles.singleCardLeft}>
          <h5 className={styles.cardHeading}>Performed Pooja Details</h5>

          {poojaDetails.length > 0 ? (
            poojaDetails.map((pooja, index) => (
              <div className={styles.poojaContainer} key={index}>
                <p className={styles.categoryTitle}>
                  {pooja?.category || "No Category"}
                </p>
                <div className={styles.poojaGrid}>
                  {pooja?.subCategories?.map((item, subIndex) => (
                    <div className={styles.poojaItem} key={subIndex}>
                      <img
                        src={item?.poojaImage || devalayaPic}
                        alt={item?.poojaName || "Pooja Image"}
                        className={styles.poojaItemImage}
                      />
                      <p className={styles.poojaItemText}>
                        {item?.poojaName || "Unnamed Pooja"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className={styles.noData}>No Performed Pooja Details Available</p>
          )}
        </div>

        {/* Service Temple */}
        <div className={styles.singleCardRight}>
          <h5 className={styles.cardHeading}>Service Temple</h5>
          <div className={styles.serviceList}>
            {services.length > 0 ? (
              services?.map((service, index) => (
                <div className={styles.serviceItem} key={index}>
                  <img
                    className={styles.serviceIcon}
                    src={service?.profileImage ?? devalayaPic}
                    alt=""
                  />

                  <div className={styles.serviceInfo}>
                    <h4>{service?.templeName}</h4>
                    <p>
                      <span className={styles.locationIcon}>
                        <img
                          src={googleMapIcon}
                          height={10}
                          width={10}
                          alt="Location Icon"
                        />
                      </span>
                      {service?.address?.city}
                    </p>
                    <p>01/11/2022 - 02/12/2022</p>
                  </div>
                </div>
              ))
            ) : (
              <p className={styles.noData}>No Service Temple Available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPanditProfileDetails;
