import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './style.module.css';
import viewIcon from '../../assets/images/viewIcons.png'; 

const ViewItem = ({ item, formFields, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={viewIcon} alt="View" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>{title}</p>
        </div>
        <div className={styles.contentMain}>
          {formFields.map((field) => (
            <div className={styles.fieldContainer} key={field.name}>
              <div className={styles.fieldLabel}>
                <p>{field.label}</p>
              </div>
              <div className={styles.fieldInput}>
                {field.type === 'text' && (
                  <input
                    type="text"
                    name={field.name}
                    value={item[field.name] || ''}
                    disabled
                  />
                )}
                {field.type === 'image' && (
                  <img src={item[field.name]} alt={field.label} />
                )}
                {field.type === 'object' && (
                  <input
                    type="text"
                    name={field.name}
                    value={item[field.name]?.[field.objectLabelKey] || ''}
                    disabled
                  />
                )}
              </div>
            </div>
          ))}
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Ok</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewItem;
