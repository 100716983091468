import React, { useState } from "react";
import styles from "./createBanner.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";

function CreateBanner() {
  const navigate = useNavigate();
  const initialFormData = {
    title: "",
    imageUrl: "",
    startDate: "",
    endDate: "",
    content: "",
    targetAudience: "",
    linkUrl: "",
    geographicalScope: "",
    language: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];
  const targetAudiences = ["Existing customers", "New customers"];
  const languages = ["Hindi", "English", "Sanskrit"];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(value)) {
        toast.error("Please enter only letters");
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveClick = async () => {
    if (validateForm()) {
      try {
        await userRequest.post("master/createMasterBanner", formData);
        setFormData(initialFormData);
        navigate("/bannerManagement");
        toast.success("Saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.title) {
      toast.error("Title is required.");
      isValid = false;
    }

    if (!formData.imageUrl) {
      toast.error("Image URL is required.");
      isValid = false;
    }

    if (!formData.startDate) {
      toast.error("Start Date is required.");
      isValid = false;
    }

    if (!formData.endDate) {
      toast.error("End Date is required.");
      isValid = false;
    }
    if (!formData.content) {
      toast.error("Content is required.");
      isValid = false;
    }
    if (!formData.targetAudience) {
      toast.error("Target audience is required.");
      isValid = false;
    }
    if (!formData.linkUrl) {
      toast.error("Link Url is required.");
      isValid = false;
    }
    if (!formData.geographicalScope) {
      toast.error("Geographical Scope is required.");
      isValid = false;
    }
    if (!formData.language) {
      toast.error("Language is required.");
      isValid = false;
    }

    return isValid;
  };

  const languageOptions = languages.map((language) => ({
    value: language,
    label: language,
  }));

  const geographicalScopeOptions = geographicalScopes.map((geo) => ({
    value: geo,
    label: geo,
  }));

  const targetAudienceOptions = targetAudiences.map((aud) => ({
    value: aud,
    label: aud,
  }));

  const handleDiscard = () => {
    navigate("/bannermanagement");
  };

  const breadcrumb = [
    { label: "Banner Management", path: "/bannermanagement" },
    { label: "Create Banner", path: "/bannermanagement/create" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Banner Title</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Image URL</p>
                </div>
                <div className={styles.fieldInput}>
                  <FileUpload
                    label="Upload Image"
                    onChange={(fieldName, file) =>
                      setFormData({ ...formData, imageUrl: file })
                    }
                    fieldName="uploadImage"
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Content</p>
                </div>
                <div className={styles.fieldInput}>
                  <textarea
                    type="text"
                    name="content"
                    value={formData.content}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Link URL</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="linkUrl"
                    value={formData.linkUrl}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Target Audience</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={targetAudienceOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        targetAudience: selectedOption.value,
                      })
                    }
                    initialSelectedOption={formData?.targetAudience}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Start Date</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Geographical Scope</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={geographicalScopeOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        geographicalScope: selectedOption.value,
                      })
                    }
                    initialSelectedOption={formData.geographicalScope}
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>End Date</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Language</p>
                </div>
                <div className={styles.fieldInput}>
                  <SelectComponent
                    options={languageOptions}
                    onSelect={(selectedOption) =>
                      setFormData({
                        ...formData,
                        language: selectedOption.value,
                      })
                    }
                    initialSelectedOption={formData.language}
                  />
                </div>
              </div>
            </div>
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBanner;
