import React, { useEffect, useState } from "react";
import styles from "./CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import Stepper from "awesome-react-stepper";
import { Spin } from "antd";
import axios from "axios";

function CreateCampaign() {
  const navigate = useNavigate();
  const [rules, setRules] = useState([""]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [levelAdded, setLevelAdded] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [rolesType, setRolesType] = useState([]);
  const initialCampaignDetails = {
    campaignTitle: "",
    NoOfLevels: 0,
    Duration: {
      startDate: "",
      endDate: "",
    },
    roleId: "",
    country: "",
    state: "",
    rule: [""],
    termsAndConditions: "",
    status: "Draft",
    levelsData: [],
  };
  const [campaignDetails, setCampaignDetails] = useState(
    initialCampaignDetails
  );

  useEffect(() => {
    // Fetch task types from the API
    const fetchTaskTypes = async () => {
      try {
        const response = await userRequest.get("/master/getMasterTaskType");
        setTaskTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchTaskTypes();
  }, []);

  useEffect(() => {
    // Fetch task types from the API
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRolesType(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleAddRule = () => {
    setRules([...rules, ""]);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      rule: [...prevDetails.rule, ""],
    }));
  };

  const handleRemoveRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList.splice(index, 1);
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleRuleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index] = event.target.value;
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList[index] = event.target.value;
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleAddLevel = () => {
    setLevelAdded(true);
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: [
        ...prevState.levelsData,
        {
          levelName: "",
          levelDescription: "",
          totalTasks: 0,
          maxCoins: 0,
          coins: "",
          tasksData: [],
        },
      ],
      NoOfLevels: prevState.levelsData.length + 1,
    }));
    setTimeout(() => {
      setLevelAdded(false);
    }, 1000);
  };

  const handleRemoveLevel = (index) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: prevState.levelsData.filter((_, i) => i !== index),
    }));
  };

  const handleAddTask = (levelIndex) => {
    setTaskAdded(true);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].totalTasks =
        newLevelsData[levelIndex].tasksData.length + 1;
      newLevelsData[levelIndex].tasksData.push({
        taskName: "",
        taskDescription: "",
        coinValue: 0,
        taskCount: 1,
        operationId: "",
        taskType: "",
        numberOfDays: 0,
      });
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
    setTimeout(() => {
      setTaskAdded(false);
    }, 1000);
  };

  const handleRemoveTask = (levelIndex, taskIndex) => {
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.splice(taskIndex, 1);
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[a-zA-Z\s]*$/;

    if (name === "campaignTitle") {
      if (!regex.test(value)) {
        toast.error("Campaign Title should contain only letters");
        return;
      }
    }
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      Duration: {
        ...prevState.Duration,
        [name]: value,
      },
    }));
  };

  const roleOptions = rolesType.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));

  const coinOptions = [
    { value: "Tej", label: "Tej" },
    { value: "Lakshmi", label: "Lakshmi" },
  ];

  const handleRoleSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };

  const handleCoinSelect = (option, levelIndex) => {
    setCampaignDetails((prevState) => {
      console.log("Prev state:", prevState);
      const newState = {
        ...prevState,
        levelsData: prevState.levelsData.map((levelDetail, idx) => {
          if (idx === levelIndex) {
            return {
              ...levelDetail,
              coins: option.value,
            };
          }
          return levelDetail;
        }),
      };
      return newState;
    });
  };

  const handleTermsChange = (value) => {
    setTermsAndConditions(value);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      termsAndConditions: value,
    }));
  };

  const handleSave = async () => {
    console.log("selectedCountry", selectedCountry);
    console.log("selectedState", selectedState);

    const {
      campaignTitle,
      NoOfLevels,
      Duration,
      roleId,
      rule,
      termsAndConditions,
      levelsData,
    } = campaignDetails;

    let missingFields = [];

    if (!campaignTitle) missingFields.push("Campaign Title");
    if (!NoOfLevels) missingFields.push("Number of Levels");
    if (!Duration.startDate) missingFields.push("Start Date");
    if (!Duration.endDate) missingFields.push("End Date");
    if (!roleId) missingFields.push("Role");
    if (!selectedCountry) missingFields.push("Country"); // Check if a country is selected
    if (!selectedState) missingFields.push("State"); // Check if a state is selected
    if (!rule) missingFields.push("Rule");
    if (!termsAndConditions) missingFields.push("Terms and Conditions");

    levelsData.forEach((level, levelIndex) => {
      if (!level.levelName) {
        missingFields.push(`Level ${levelIndex + 1} Name`);
      }
      level.tasksData.forEach((task, taskIndex) => {
        if (!task.taskName) {
          missingFields.push(
            `Level ${levelIndex + 1} Task ${taskIndex + 1} Name`
          );
        }
        if (!task.coinValue) {
          missingFields.push(
            `Level ${levelIndex + 1} Task ${taskIndex + 1} Coin Value`
          );
        }
      });
    });

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in all required fields: ${missingFields.join(", ")}`
      );
      return;
    }

    const { startDate, endDate } = campaignDetails.Duration;
    if (new Date(endDate) <= new Date(startDate)) {
      toast.error("End date must be greater than start date");
      return;
    }
    // Update campaignDetails with selected country and state before saving
    const updatedCampaignDetails = {
      ...campaignDetails,
      countryId: selectedCountry?.value, // Save the country ID or name as required
      stateId: selectedState?.value, // Save the state ID or name as required
    };

    try {
      let response = await userRequest.post(
        "/admin/svarna/addCampaign",
        updatedCampaignDetails
      );
      if (response?.status === 201) {
        navigate("/svarnamanagement");
        toast.success("Saved successfully!");
        setCampaignDetails(initialCampaignDetails);
      }
    } catch (error) {
      console.log("Error saving campaign:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleLevelInputChange = (index, field, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (field === "levelName") {
      if (!regex.test(value)) {
        toast.error("level Name  should contain only letters");
        return;
      }
    }
    setCampaignDetails((prevState) => {
      const newLevel = [...prevState.levelsData];
      if (newLevel[index]) {
        if (field === "levelName") {
          newLevel[index].levelName = value;
          newLevel[index].levelNumber = index + 1;
          newLevel[index].levelDescription = value;
        } else if (field === "totalTasks") {
          newLevel[index].totalTasks = parseInt(value) || 0;
        } else if (field === "maxCoins") {
          newLevel[index].maxCoins = parseInt(value) || 0;
        } else if (field === "coins") {
          newLevel[index].coins = value;
        }
        return {
          ...prevState,
          levelsData: newLevel,
        };
      } else {
        // If newLevel[index] is undefined, return prevState as it is
        return prevState;
      }
    });
  };

  const handleTaskInputChange = (levelIndex, taskIndex, field, option) => {
    setCampaignDetails((prevState) => {
      const newLevelDetails = [...prevState.levelsData];
      const taskDetails =
        newLevelDetails[levelIndex].tasksData[taskIndex] || {};
      if (field === "taskName") {
        taskDetails.taskName = option.label;
        taskDetails.taskDescription = option.value;
        taskDetails.operationId = option.operationId;
        taskDetails.taskType = option.taskType;
      } else if (field === "coinValue") {
        taskDetails.coinValue = option;
      } else if (field === "taskDescription") {
        taskDetails.taskDescription = option.value;
      } else if (field === "taskCount") {
        taskDetails.taskCount = option;
      } else if (field === "numberOfDays") {
        taskDetails.numberOfDays = option;
      }
      newLevelDetails[levelIndex].tasksData[taskIndex] = taskDetails;
      return {
        ...prevState,
        levelsData: newLevelDetails,
      };
    });
  };

  const activeStep = campaignDetails?.levelsData?.length;

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    try {
      let response = await userRequest.get("/master/getMasterCountry");
      if (response?.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.country,
          value: item?._id,
        }));
        setCountryData(options);
      }
    } catch (error) {
      console.log("Error country:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchState();
  }, []);
  const fetchState = async (countryId) => {
    console.log("countryId", countryId);
    if (!countryId) {
      console.error("countryId is undefined");
      setStateData([{ label: "-", value: "-" }]); // Show dash if no country is selected
      return;
    }
    try {
      let response = await userRequest.get(
        `/master/getMasterState?countryId=${countryId}`
      );
      if (response.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.state,
          value: item?._id,
        }));
        setStateData(options);
      }
    } catch (error) {
      console.log("Error state:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleCountrySelect = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
    fetchState(selectedCountry?.value); // Fetch states based on selected country
  };
  const handleStateSelect = (selectedState) => {
    setSelectedState(selectedState);
  };

  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/svarnamanagement")}>
              Svarna Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Create Campaign</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button onClick={() => navigate("/svarnamanagement")}>Discard</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <p>Campaign Details</p>
          {/* </div> */}
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails.campaignTitle}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
            {/* <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Number of level</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={levels}
                  onSelect={handleLevelSelect}
                />
              </div>
            </div> */}
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>

              <div className={styles.fieldInputTwo}>
                <input
                  type="date"
                  name="startDate"
                  onChange={handleDurationChange}
                  placeholder="Enter"
                />
                <input
                  type="date"
                  name="endDate"
                  onChange={handleDurationChange}
                  placeholder="Enter"
                />
              </div>
            </div>

            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={roleOptions}
                  onSelect={handleRoleSelect}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Country</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={countryData}
                  onSelect={handleCountrySelect}
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>State</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={stateData}
                  onSelect={handleStateSelect}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxLakshmiCoins"
                  value={campaignDetails.maxLakshmiCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxTejCoins"
                  value={campaignDetails.maxTejCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Add Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div className={styles.rule}>
                      <textarea
                        value={rule}
                        onChange={(e) => handleRuleChange(index, e)}
                      />
                      {rules.length > 1 && (
                        <button
                          onClick={() => handleRemoveRule(index)}
                        ></button>
                      )}
                    </div>
                    {index === rules.length - 1 && (
                      <button onClick={handleAddRule}>Add More</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                <RichTextEditor
                  value={termsAndConditions}
                  onChange={handleTermsChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
          <button onClick={handleAddLevel}>Add More</button>
        </div>
        <Spin spinning={levelAdded} size="small">
          <Stepper
            defaultActiveStep={activeStep}
            strokeColor="#f68b21"
            fillStroke="#f68b21"
            activeColor="#f68b21"
            showProgressBar="true"
            activeProgressBorder="2px solid #f68b21"
            submitBtn={false}
            continueBtn={<button className={styles.stepperBtn}>Next</button>}
            backBtn={<button className={styles.stepperBtn}>Back</button>}
            // onSubmit={(step) => alert(`Thank you!!! Final Step -> ${step}`)}
          >
            {campaignDetails.levelsData.map((level, levelIndex) => (
              <div key={levelIndex} className={styles.stepperSubDiv}>
                <button
                  className={styles.deleteItem}
                  onClick={() => handleRemoveLevel(levelIndex)}
                ></button>
                <div className={styles.section}>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Level {levelIndex + 1}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Name of Level</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.levelName
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "levelName",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    {/* <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Number of Task</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={levelDetails[levelIndex].numberOfTasks}
                          onChange={(e) =>
                            handleNumberOfTasksChange(levelIndex, e)
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className={styles.fieldContainer}>
                    <div className={styles.feildLeft}>
                      <div className={styles.fieldLabel}>
                        <p>Max Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <input
                          type="text"
                          value={
                            campaignDetails.levelsData[levelIndex]?.maxCoins
                          }
                          onChange={(e) =>
                            handleLevelInputChange(
                              levelIndex,
                              "maxCoins",
                              e.target.value
                            )
                          }
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className={styles.feildRight}>
                      <div className={styles.fieldLabel}>
                        <p>Select Coins</p>
                      </div>
                      <div className={styles.fieldInput}>
                        <SelectComponent
                          options={coinOptions}
                          onSelect={(option) =>
                            handleCoinSelect(option, levelIndex)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.taskDetailsMain}>
                    <p>Task Details</p>
                    <button
                      className={styles.taskAdd}
                      onClick={() => handleAddTask(levelIndex)}
                      disabled={taskAdded}
                    >
                      Add Task
                    </button>
                  </div>

                  {level.tasksData.map((task, taskIndex) => (
                    <div
                      className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                    >
                      <div className={styles.deleteDiv}>
                        <button
                          className={styles.deleteItemTwo}
                          onClick={() =>
                            handleRemoveTask(levelIndex, taskIndex)
                          }
                        ></button>
                      </div>
                      <div className={styles.feildLeft}>
                        <div className={styles.fieldLabel}>
                          <p>Coin Value</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <input
                            // style={{ background: "white" }}
                            type="text"
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.coinValue
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "coinValue",
                                e.target.value
                              )
                            }
                            placeholder="Enter"
                          />
                        </div>

                        <div className={styles.fieldLabel}>
                          {/* <p>Task {taskIndex + 1}</p> */}
                          <p>Task Description</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <textarea
                            // style={{ background: "white" }}
                            value={
                              campaignDetails.levelsData[levelIndex]?.tasksData[
                                taskIndex
                              ]?.taskDescription
                            }
                            onChange={(e) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskDescription",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.feildRight}>
                        <div className={styles.fieldLabel}>
                          <p>Task Type</p>
                        </div>
                        <div className={styles.fieldInput}>
                          <SelectComponent
                            // options={taskPointOptions}
                            options={taskTypes.map((task) => ({
                              label: task.title,
                              value: task.description,
                              operationId: task._id,
                              taskType: task.taskType,
                            }))}
                            onSelect={(option) =>
                              handleTaskInputChange(
                                levelIndex,
                                taskIndex,
                                "taskName",
                                option
                              )
                            }
                            // white
                          />
                        </div>

                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Continuous" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Number Of Days</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.numberOfDays
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "numberOfDays",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                        {campaignDetails?.levelsData[levelIndex]?.tasksData[
                          taskIndex
                        ]?.taskType === "Multiple" && (
                          <>
                            <div className={styles.fieldLabel}>
                              <p>Task Count</p>
                            </div>
                            <div className={styles.fieldInput}>
                              <input
                                // style={{ background: "white" }}
                                type="text"
                                value={
                                  campaignDetails.levelsData[levelIndex]
                                    ?.tasksData[taskIndex]?.taskCount
                                }
                                onChange={(e) =>
                                  handleTaskInputChange(
                                    levelIndex,
                                    taskIndex,
                                    "taskCount",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Stepper>
        </Spin>
      </div>
    </div>
  );
}

export default CreateCampaign;
