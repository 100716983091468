import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./addUtsavType.module.css";
import { toast } from "sonner";
import { userRequest } from "../../../../../requestMethod";
import FileUpload from "../../../../../components/FileUpload/FileUpload";

const AddUtsavType = ({ fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const initialFormData = {
    icon: "",
    name: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    if (!formData.name.trim()) {
      toast.error("Name is required");
      return false;
    }
    if (/\d/.test(formData.name)) {
      toast.error("Name cannot contain numbers");
      return false;
    }
    if (!formData.icon) {
      toast.error("icon is required");
      return false;
    }
 
    
    return true;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
      await userRequest.post(`/master/createMasterUtsavType`, formData);
      toast.success("Utsav type added successfully");
      await fetchData();
      resetForm();
    } catch (error) {
      console.log("Error creating kyc:", error);
      // Show error message
      toast.error("Failed to create utsav type");
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add New
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Utsav Type</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                key={`name-${resetKey}`}
              />
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, icon: file })
                }
                fieldName="uploadMedia"
                key={`icon-${resetKey}`}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUtsavType;







// import React from "react";
// import AddItem from "../../../../../components/Modals/AddItem";


// const AddUtsavType = ({ fetchData }) => {
//   const initialFormData = {
//     name: "",
//     icon: "",
//   };

//   const apiEndpoint = "/master/createMasterUtsavType";
//   const formFields = [
//     { name: "name", label: "Utsav Type", type: "text"},
//     { name: "icon", label: "Upload Media", type: "file" },
//   ];



//   return (
//     <AddItem
//       fetchData={fetchData}
//       apiEndpoint={apiEndpoint}
//       initialFormData={initialFormData}
//       formFields={formFields}
//       title="Utsav Type"
//       feildName="icon"
//     />
//   );
// };

// export default AddUtsavType;
