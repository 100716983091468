import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./editJobCategory.module.css";
import { toast } from "sonner";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditJobCategory = ({ fetchData, item, selectedOption, workingSectorData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let initialFormData;
  if (selectedOption === "workingSector") {
    initialFormData = {
      workingSector: item?.workingSector,
    };
  }
  if (selectedOption === "jobCategory") {
    initialFormData = {
      workingSectorType: item?.workingSectorType?._id,
      jobTitle:item?.jobTitle,
      jobTitleCode: item?.jobTitleCode,
    };
  }
  const [formData, setFormData] = useState(initialFormData);
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (selectedOption === "workingSector") {
      setFormData({
        workingSector: item?.workingSector,
      });
    }
    if (selectedOption === "jobCategory") {
      setFormData({
        
        workingSectorType: item?.workingSectorType?._id,
        jobTitle:item?.jobTitle,
        jobTitleCode: item?.jobTitleCode,
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOk = async () => {
    setIsModalOpen(false);

    try {
      if (selectedOption === "jobCategory") {
        await userRequest.put(
          `/master/updateMasterJobCategory/${item?._id}`,
          formData
        );
      }
      if (selectedOption === "workingSector") {
        await userRequest.put(
          `/master/updateMasterWorkingSector/${item?._id}`,
          formData
        );
      }

      toast.success(
        `${
          selectedOption === "workingSector" ? "working sector" : "job category"
        } updated successfully`
      );
      await fetchData();
      if (selectedOption === "workingSector") {
        setFormData({
          workingSector: item?.workingSector,
        });
      }
      if (selectedOption === "jobCategory") {
        setFormData({
          workingSectorType: item?.workingSectorType?._id,
          jobTitle:item?.jobTitle,
          jobTitleCode: item?.jobTitleCode,
        });
      }
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      // Show error message
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>
            {`Edit ${
              selectedOption === "workingSector"
                ? "Working Sector"
                : "Job Category"
            }`}
          </p>
        </div>
        <div className={styles.contentMain}>
        {
            selectedOption === "jobCategory" && (
              <>
              <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
              Working Sector
              </p>
            </div>
            <div className={styles.fieldInput}>
              {/* <input
                type="text"
                name="jobTitleCode"
                value={formData.jobTitleCode}
                onChange={handleChange}
                key={`name-${resetKey}`}
              /> */}
               <select
                value={formData.workingSectorType || ""}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    workingSectorType: e.target.value,
                  })
                }
              >
                <option value="" disabled>Select</option>
                {workingSectorData?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.workingSector}
                  </option>
                ))}
              </select>
            </div>
          </div>
              </>
            )
          }
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
                {selectedOption === "workingSector"
                  ? "Working sector"
                  : "Job Title"}
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name={
                  selectedOption === "workingSector"
                    ? "workingSector"
                    : "jobTitle"
                }
                value={
                  selectedOption === "workingSector"
                    ? formData?.workingSector
                    : formData?.jobTitle
                }
                onChange={handleChange}
              />
            </div>
          </div>
          {
            selectedOption === "jobCategory" && (
              <>
              <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
              Job Code
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="jobTitleCode"
                value={formData?.jobTitleCode}
                onChange={handleChange}
                
              />
            </div>
          </div>
              </>
            )
          }
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditJobCategory;
