import React, { useEffect, useState } from "react";
import styles from "../CreateRoles/CreateRoles.module.css";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function EditRoles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await userRequest.get(
          "/permissions/getAllPermissions"
        );
        const adminRoles = await userRequest.get(
          "/adminRoles/getAllAdminRoles"
        );
        setPermissions(response?.data?.data);
        setRoles(adminRoles?.data?.data);
      } catch (error) {
        console.error("Error fetching permissions:", error.message);
        alert("Failed to fetch permissions. Please try again later.");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const userId = location?.state?.item?._id;
    if (userId) {
      fetchData(userId);
    }
  }, [location.state?.item?._id, permissions]);

  const fetchData = async (id) => {
    try {
      const response = await userRequest.get(
        `/adminUserPermission/getParticularUserForAdmin/${id}`
      );
      const updatedData = {
        ...response?.data?.data,
        permissions: response?.data?.data.permissions.map((permission) => ({
          ...permission,
          checked: true, // Assuming all permissions are checked by default
        })),
      };

      const permissionArr = response?.data?.data.permissions;
      const modifiedP2 = permissions.map((objP2) => {
        return {
          ...objP2,
          isSelected: permissionArr.some((permission) => {
            return (
              permission.permissionsId.permissionName === objP2.permissionName
            );
          }),
        };
      });
      setData({ ...response?.data?.data, permissions: modifiedP2 });
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handlePermissionChange = (permissionId) => {
    const updatedPermissions = data?.permissions?.map((permission) => {
      if (permission._id === permissionId) {
        return {
          ...permission,
          isSelected: !permission.isSelected, // Toggle the checked state
        };
      }
      return permission;
    });
    setData((prevData) => ({
      ...prevData,
      permissions: updatedPermissions,
    }));
  };

  const saveData = async () => {
    try {
      const selectedPermissions = data.permissions.filter(
        (permission) => permission.isSelected
      );

      const permissionsData = selectedPermissions.map((permission) => ({
        permissionsId: permission?._id,
      }));

      const payload = {
        name: data.userId?.name,
        email: data.userId?.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        roleId: data.userId?.roleId._id,
        designation: data.userId?.designation,
        activeStatus: data.activeStatus,
        permissions: permissionsData,
      };

      let response = await userRequest.put(
        `/adminUserPermission/updateUserForAdmin/${data?._id}`,
        payload
      );
      await fetchData(location.state?.item?._id);
      toast.success("Saved successfully!");
      navigate("/rolesandpermissions");
    } catch (error) {
      console.log("Error saving data:", error);
      toast.error(error.message);
    }
  };

  const options = roles?.map((role) => ({
    value: role?._id,
    label: role?.roleName,
  }));

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          {
            label: "Roles and Permissions Management",
            path: "/rolesandpermissions",
          },
          { label: "Edit", path: `#` },
        ]}
        discardLabel={"Discard"}
        saveLabel={"Save"}
        onDiscardClick={() => navigate("/rolesandpermissions")}
        onSaveClick={saveData}
      />
      <div className={styles.contentContainer}>
        <h4>Basic Details</h4>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.name || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    userId: { ...data.userId, name: e.target.value },
                  })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.email || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    userId: { ...data.userId, email: e.target.value },
                  })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.password || ""}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>UserType</p>
            </div>
            <div className={styles.fieldInput}>
              <select
                value={data?.userId?.roleId?._id || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    userId: {
                      ...data.userId,
                      roleId: {
                        ...data.userId.roleId,
                        _id: e.target.value,
                      },
                    },
                  })
                }
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.userId?.designation || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    userId: { ...data.userId, designation: e.target.value },
                  })
                }
                placeholder="Enter"
              />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                value={data?.confirmPassword || ""}
                onChange={(e) =>
                  setData({ ...data, confirmPassword: e.target.value })
                }
                placeholder="Enter"
              />
            </div>
          </div>
        </div>
        <div className={styles.permissionDiv}>
          <h5>Permissions</h5>
          <div className={styles.rolesTable}>
            <table>
              <tbody>
                {data?.permissions?.map((permission) => (
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={permission.isSelected}
                          onChange={() =>
                            handlePermissionChange(permission._id)
                          }
                        />
                        <p className={styles.permissionNamePara}>
                          {permission?.permissionName}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRoles;
