import React from "react";
import ViewItem from "../../../../../components/Modals/ViewItem";

const ViewRelationshipStatus = ({ fetchData, item }) => {

  const formFields = [{ name: "name", label: "Name", type: "text" }];

  return (
    <ViewItem
      formFields={formFields}
      title="View RelationshipStatus"
      item={item}
    />
  );
};

export default ViewRelationshipStatus;


