import React from "react";
import EditItem from "../../../../../components/Modals/EditItem";

const EditDevalayaType = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
    rules: item?.rules,
  };

  const apiEndpoint = "/master/editAdminMasterRules";
  const formFields = [{ name: "name", label: "Name", type: "text" } , { name: "rules", label: "Rules", type: "text" }];


  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Rules"
      item={item}
    />
  );
};

export default EditDevalayaType;