import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./EditIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditLocalGuide = ({fetchData,task}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: task?.name,
    subText: task?.subText,
    icon: task?.icon,
  });

  useEffect(() => {
    setFormData({
      name: task?.name,
      subText: task?.subText,
      icon: task?.icon,
    });
  }, [task]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    if (!formData.name.trim()) {
      toast.error("Name is required");
      return false;
    }
    if (/\d/.test(formData.name)) {
      toast.error("Name cannot contain numbers");
      return false;
    }
    if (!formData.icon) {
      toast.error("icon is required");
      return false;
    }
    if (!formData.subText.trim()) {
      toast.error("subText is required");
      return false;
    }
        // Update formData with trimmed values before submitting
        setFormData({
          ...formData,
          name: formData.name.trim(),
          subText: formData.subText.trim(),
        });
    return true;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
      await userRequest.patch(`/master/editAdminMasterLocalTransport/${task?._id}`, formData);
      
      toast.success("Local Guide updated successfully");
      await fetchData()
      setFormData({
        name: task?.name,
        subText: task?.subText,
        icon: task?.icon,
      })
    } catch (error) {
      console.log("Error updating Local Guide:", error);
      // Show error message
      toast.error("Failed to update Local Guide");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Local Guide</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Sub Text</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="subText"
                value={formData.subText}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, icon: file })
                }
                fieldName="uploadMedia"
                name={formData.icon}
              />
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditLocalGuide;

