// import React, { useEffect, useState } from "react";
// import styles from "./user.module.css";
// import { useNavigate } from "react-router-dom";
// import { userRequest } from "../../../requestMethod";
// import { Popconfirm, Spin, Switch } from "antd";
// import Filters from "./Filters";
// import SelectDeactivateReason from "../Reason/SelectDeactivateReason";
// import { getConfig } from "../../../utils/getConfig";
// import { toast } from "sonner";
// import useDebounce from "../../../utils/useDebounce";

// function User() {
//   const { BASE_URL } = getConfig();
//   const navigate = useNavigate();
//   const [userData, setUserData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const debouncedSearchQuery = useDebounce(searchQuery, 1000);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [selectedReason, setSelectedReason] = useState("");
//   const [id, setId] = useState("");
//   const [userType, setUserType] = useState("");
//   const [reasonModalVisible, setReasonModalVisible] = useState(false);

//   const handleNext = () => {
//     if (page < totalPages) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     fetchData();
//   }, [debouncedSearchQuery, page, filterData]);

//   const fetchData = async () => {
//     try {
//       const filterQuery = filterData
//         ? `&userType=${filterData?.userType}&city=${filterData.city}`
//         : "";
//       let response;

//       if (filterQuery) {
//         const response = await userRequest.get(
//           `/userManagement/getUserManagementList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
//         );
//       } else {
//         response = await userRequest.get(
//           `/userManagement/getUserManagementList?userType=All&search=${debouncedSearchQuery}${filterQuery}&page=${page}`
//         );
//       }

//       setUserData(response.data.data);
//       setTotalPages(response.data.pagination.totalPages);
//       setCurrentPage(response.data.pagination.page);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleChange = async (id, activeStatus, userType) => {
//     try {
//       setId(id);
//       setUserType(userType);
//       const newActiveStatus = !activeStatus;
//       if (!newActiveStatus) {
//         // If deactivating, show reason modal
//         setSelectedReason("");
//         setReasonModalVisible(true);
//       } else {
//         await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
//           userId: id,
//           userType: userType,
//           activeStatus: newActiveStatus,
//           deactivationReason: "",
//         });
//         fetchData();
//       }
//     } catch (error) {
//       console.error("Error updating user status:", error);
//     }
//   };

//   const handleFilterClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalOk = (data) => {
//     setFilterData(data);
//     setIsModalOpen(false);
//   };

//   const handleModalCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleExport = async () => {
//     try {
//       let exportUrl = `/userManagement/exportUserManagementToExcel?userType=All&search=${debouncedSearchQuery}&page=${page}`;
//       const exportResponse = await userRequest.get(exportUrl, {
//         responseType: "blob",
//       });

//       // Create a Blob object from the response data
//       const blob = new Blob([exportResponse.data], {
//         type: "application/octet-stream",
//       });

//       // Create a URL for the blob and initiate download
//       const downloadUrl = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = downloadUrl;
//       a.download = "exported_data.xlsx"; // Set the file name here
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error("Error exporting data:", error);
//       toast.error("Error exporting data. Please try again later.");
//     }
//   };

//   return (
//     <Spin spinning={loading} size="small">
//       <div className={styles.tableMain}>
//         <div className={styles.filterExport}>
//           <p>All</p>
//           <div className={styles.search}>
//             <input
//               type="text"
//               onChange={(e) => setSearchQuery(e.target.value)}
//               placeholder="Search..."
//             />
//           </div>
//           <div className={styles.export}>
//             <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
//           </div>
//           <div className={styles.filterDiv}>
//             <p onClick={handleFilterClick}>Filter</p>
//           </div>
//         </div>
//         <div className={styles.userTableMain}>
//           <table>
//             <thead>
//               <tr>
//                 <th>S. No.</th>
//                 <th>User Name</th>
//                 <th>User Type</th>
//                 <th>Email Id</th>
//                 <th>Nationality</th>
//                 <th>City</th>
//                 <th>Profile Completed</th>
//                 <th>Action</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {userData.length > 0 ? (
//                 userData.map((user, index) => (
//                   <tr key={user._id}>
//                     <td>{(page - 1) * 10 + index + 1}</td>
//                     <td>
//                       {user.firstName} {user.lastName}
//                     </td>
//                     <td>{user.roleId.roleName}</td>
//                     <td>{user.email}</td>
//                     <td>{user.nationality}</td>
//                     <td>{user?.placesLived?.currentCity}</td>
//                     <td>{user?.profileCompletionPercentage}%</td>
//                     <td
//                       className={styles.viewDetails}
//                       onClick={() =>
//                         navigate(
//                           `/usermanagement/details/${user._id}`
//                         )
//                       }
//                     >
//                       Details
//                     </td>
//                     <td className={styles.actionTd}>
//                       <Popconfirm
//                         placement="left"
//                         title={`${
//                           user.activeStatus ? "Deactivate" : "Activate"
//                         } ${user.firstName} ${user.lastName} from tej`}
//                         description={`Are you sure to ${
//                           user.activeStatus ? "deactivate" : "activate"
//                         } ${user.firstName} ${user.lastName} from Tej`}
//                         okText="Yes"
//                         cancelText="No"
//                         onConfirm={() =>
//                           handleChange(user._id, user.activeStatus, user.role)
//                         }
//                       >
//                         <Switch size="small" checked={user.activeStatus} />
//                       </Popconfirm>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="8">No data found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//         {userData.length > 0 && (
//           <div className={styles.footer}>
//             <div
//               className={styles.action}
//               style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
//             >
//               <button
//                 className={`${styles.actionButton} ${
//                   page === 1 ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handlePrevious}
//                 disabled={page === 1}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`${styles.actionButton} ${
//                   page === totalPages ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handleNext}
//                 disabled={page === totalPages}
//               >
//                 Next
//               </button>
//             </div>
//             <div className={styles.page}>
//               <span>{currentPage}</span> <span>of</span>{" "}
//               <span>{totalPages}</span>
//             </div>
//           </div>
//         )}
//         <Filters
//           isModalOpen={isModalOpen}
//           handleOk={handleModalOk}
//           handleCancel={handleModalCancel}
//         />

//         <SelectDeactivateReason
//           selectedReason={selectedReason}
//           setSelectedReason={setSelectedReason}
//           isModalOpen={reasonModalVisible}
//           setIsModalOpen={setReasonModalVisible}
//           fetchData={fetchData}
//           id={id}
//           userType={userType}
//         />
//       </div>
//     </Spin>
//   );
// }

// export default User;


import React, { useEffect, useState } from "react";
import styles from "./user.module.css";
import { userRequest } from "../../../requestMethod";
import { Popconfirm, Spin, Switch } from "antd";
import Filters from "./Filters";
import SelectDeactivateReason from "../Reason/SelectDeactivateReason";
import useDebounce from "../../../utils/useDebounce";
import TableComponent from "../../../components/TableComponent/TableComponent";
import SearchFilterComponent from "../../../components/SearchFilter/SearchFilterComponent";
import { handleExportDoc } from "../../../utils/exportDoc";
import { useNavigate } from "react-router-dom";


function User() {
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState("");
  const [id, setId] = useState("");
  const [userType, setUserType] = useState("");
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);
  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&userType=${filterData?.userType}&city=${filterData.city}`
        : "";
      let response;

      if (filterQuery) {
        response = await userRequest.get(
          `/userManagement/getUserManagementList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      } else {
        response = await userRequest.get(
          `/userManagement/getUserManagementList?userType=All&search=${debouncedSearchQuery}${filterQuery}&page=${page}`
        );
      }

      setUserData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus, userType) => {
    try {
      setId(id);
      setUserType(userType);
      const newActiveStatus = !activeStatus;
      if (!newActiveStatus) {
        // If deactivating, show reason modal
        setSelectedReason("");
        setReasonModalVisible(true);
      } else {
        await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
          userId: id,
          userType: userType,
          activeStatus: newActiveStatus,
          deactivationReason: "",
        });
        fetchData();
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = () => {
    handleExportDoc(
      `/userManagement/exportUserManagementToExcel?userType=All`,
      "UserManagement.xlsx"
    );
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Name",
      key: "userName",
      render: (item) =>
        `${item.firstName} ${item.lastName}`,
    },
    { 
      title: "User Type", 
      key: "roleName" ,
      render: (item) =>
        `${item.roleId.roleName}`,
  
    },
    { title: "Email Id", key: "email" },
    { title: "Nationality", key: "nationality" },
    {
      title: "City",
      key: "city",
      render: (item) => item.placesLived?.currentCity,
    },
    {
      title: "Profile Completed",
      key: "profileCompletionPercentage",
      render: (item) => 
        `${item.profileCompletionPercentage}%`,
    },

  
    {
      title: "Action",
      key: "details",
      render: (item) => (
        <div
          className={styles.viewDetails}
          onClick={() =>
            navigate(`/usermanagement/details/${item._id}`)
          }
        >
          Details
        </div>
      ),
    },

    {
      title: "Status",
      key: "activeStatus",
      render: (item) => (
        <Popconfirm
          title={`Are you sure to ${
            item.activeStatus ? "deactivate" : "activate"
          } ${item.firstName} ${item.lastName}?`}
          onConfirm={() => handleChange(item._id, item.activeStatus , item.roleId.roleName)}
          okText="Yes"
          cancelText="No"
        >
          <Switch size="small" checked={item.activeStatus} />
        </Popconfirm>
      ),
    },
  ];

  const customWidths = {
    serialNumber: "8vw",
    userName: "20vw",
    roleName: "20vw",
    category: "10vw",
    email: "20vw",
    nationality: "8vw",
    city: "20vw",
    profileCompletionPercentage: "20vw",
    activeStatus: "20vw",
  };

  return (
    <>
  
          <SearchFilterComponent
            setSearchQuery={setSearchQuery}
            onExportClick={handleExport}
            onFilterClick={handleFilterClick}
            filter
          />

             <TableComponent
            columns={columns}
            data={userData}
            loading={loading}
            onDelete={() => {}}
            onStatusChange={handleChange}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
            customWidths={customWidths}
          />
     
        <Filters
          isModalOpen={isModalOpen}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
        />

        <SelectDeactivateReason
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          isModalOpen={reasonModalVisible}
          setIsModalOpen={setReasonModalVisible}
          fetchData={fetchData}
          id={id}
          userType={userType}
        />
    </>
  );
}

export default User;
