import React, { useEffect, useState } from "react";
import styles from "./campaign.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import { Spin, Switch } from "antd";
import CampaignFilter from "./CampaignFilter";
import { getConfig } from "../../../utils/getConfig";
import { toast } from "sonner";
import useDebounce from "../../../utils/useDebounce";
function Campaign() {
  const { BASE_URL } = getConfig();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
      ? `&country=${filterData?.country}&state=${filterData.state}&NoOfLevels=${filterData?.NoOfLevels}`
      : "";
      const response = await userRequest.get(
        `/admin/svarna/getAdminCampaign?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setCampaignData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.patch(
        `/admin/svarna/updateCampaignStatus`,
        {
          campaignId: id,
          activeStatus: newActiveStatus
        }
      )
      fetchData()
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };
 const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = async () => {
    try {
      let exportUrl = `/admin/svarna/exportCampaignDataToExcel?search=${searchQuery}&page=${page}`;
      const exportResponse = await userRequest.get(exportUrl, {
        responseType: "blob",
      });

      // Create a Blob object from the response data
      const blob = new Blob([exportResponse.data], {
        type: "application/octet-stream",
      });

      // Create a URL for the blob and initiate download
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "exported_data.xlsx"; // Set the file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("Error exporting data. Please try again later.");
    }
  };

  return (
    <div className={styles.tableMain}>
      <div className={styles.filterExport}>
        {/* <p>All</p> */}
        <div className={styles.search}>
          <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        <div className={styles.export}>
            <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
          </div>
        <div className={styles.filterDiv}>
              <p onClick={handleFilterClick}>Filter</p>
            </div>
      </div>
      <Spin spinning={loading} size="small">
      <div className={styles.campaignTableMain}>
      <table>
        <thead>
          <tr>
            <th>ID.</th>
            <th>Campaign name</th>
            <th>Country</th>
            <th>State</th>
            <th>End Date</th>
            <th>No of Level</th>
            <th>Total Task</th>
            <th>Max Tej Coins</th>
            <th>Max Lakshmi Coins</th>
            <th>Completed By</th>
            <th>Details</th>
            <th>Action & Status</th>
          </tr>
        </thead>
        <tbody>
          {campaignData.length > 0 ? (
            campaignData.map((campaign) => (
              <tr key={campaign._id}>
                <td>{campaign._id}</td>
                <td>{campaign.campaignTitle}</td>
                <td>{campaign?.countryId?.country}</td>
                <td>{campaign?.stateId?.state}</td>
                <td>
                  {new Date(campaign?.Duration?.endDate).toLocaleDateString()}
                </td>
                <td>{campaign.NoOfLevels}</td>
                <td>{campaign.totalTasks}</td>
                <td>{campaign.maxTejCoins}</td>
                <td>{campaign.maxLakshmiCoins}</td>
                <td>{campaign.userCount}</td>
                <td
                  className={styles.viewDetails}
                  onClick={() =>
                    navigate(`/svarnamanagement/viewcampaign/${campaign._id}`)
                  }
                >
                  View
                </td>
                <td className={styles.actionTd}>
                  <img
                    src={editIcon}
                    alt=""
                    onClick={() =>
                      navigate(`/svarnamanagement/editcampaign/${campaign?._id}`)
                    }
                  />
               <Switch size="small" checked={campaign.activeStatus} onChange={() => handleChange(campaign._id, campaign.activeStatus)}/>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>

      {campaignData.length > 0 ? (
          <div className={styles.footer}>
          <div
            className={styles.action}
            style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
          >
            <button
              className={`${styles.actionButton} ${
                page === 1 ? styles.disabled : styles.activeButton
              }`}
              onClick={handlePrevious}
              disabled={page === 1}
            >
              Previous
            </button>
            <button
              className={`${styles.actionButton} ${
                page === totalPages ? styles.disabled : styles.activeButton
              }`}
              onClick={handleNext}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
          <div className={styles.page}>
            <span>{currentPage}</span> <span>of</span>{" "}
            <span>{totalPages}</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      </Spin>
       <CampaignFilter isModalOpen={isModalOpen} handleOk={handleModalOk} handleCancel={handleModalCancel} />
    </div>
  );
}

export default Campaign;

// import React, { useEffect, useState } from "react";
// import styles from "./campaign.module.css";
// import { useNavigate } from "react-router-dom";
// import { userRequest } from "../../../requestMethod";
// import editIcon from "../../../assets/images/edit.png";
// import { Popconfirm, Spin, Switch } from "antd";
// import CampaignFilter from "./CampaignFilter";
// import { getConfig } from "../../../utils/getConfig";
// import { toast } from "sonner";
// import useDebounce from "../../../utils/useDebounce";
// import TableComponent from "../../../components/TableComponent/TableComponent";
// function Campaign() {
//   const { BASE_URL } = getConfig();
//   const navigate = useNavigate();
//   const [campaignData, setCampaignData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const debouncedSearchQuery = useDebounce(searchQuery, 1000);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [filterData, setFilterData] = useState(null);
//   const [loading, setLoading] = useState(true)

//   const handleNext = () => {
//     if (page < totalPages) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     fetchData();
//   }, [debouncedSearchQuery, page, filterData]);

//   const fetchData = async () => {
//     try {
//       const filterQuery = filterData
//       ? `&country=${filterData?.country}&state=${filterData.state}&NoOfLevels=${filterData?.NoOfLevels}`
//       : "";
//       const response = await userRequest.get(
//         `/admin/svarna/getAdminCampaign?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
//       );
//       setCampaignData(response.data.data);
//       setTotalPages(response.data.pagination.totalPages);
//       setCurrentPage(response.data.pagination.page);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }finally{
//       setLoading(false)
//     }
//   };

//   const handleChange = async (id, activeStatus) => {
//     try {
//       const newActiveStatus = !activeStatus;
//       await userRequest.patch(
//         `/admin/svarna/updateCampaignStatus`,
//         {
//           campaignId: id,
//           activeStatus: newActiveStatus
//         }
//       )
//       fetchData()
//     } catch (error) {
//       console.error("Error updating user status:", error);
//     }
//   };
//  const handleFilterClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalOk = (data) => {
//     setFilterData(data);
//     setIsModalOpen(false);
//   };

//   const handleModalCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleExport = async () => {
//     try {
//       let exportUrl = `/admin/svarna/exportCampaignDataToExcel?search=${searchQuery}&page=${page}`;
//       const exportResponse = await userRequest.get(exportUrl, {
//         responseType: "blob",
//       });

//       // Create a Blob object from the response data
//       const blob = new Blob([exportResponse.data], {
//         type: "application/octet-stream",
//       });

//       // Create a URL for the blob and initiate download
//       const downloadUrl = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = downloadUrl;
//       a.download = "exported_data.xlsx"; // Set the file name here
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error("Error exporting data:", error);
//       toast.error("Error exporting data. Please try again later.");
//     }
//   };

//   const columns = [
//     {
//       title: "S.No.",
//       key: "serialNumber",
//       render: (item, index) => (page - 1) * 10 + index + 1,
//     },
//     {
//       title: "Campaign Name",
//       key: "campaignTitle",
//       render: (item) =>
//         `${item.campaignTitle}`,
//     },
//     { 
//       title: "Country", 
//       key: "countryId" ,
//       render: (item) =>
//         `${item?.countryId?.country}`,
  
//     },
//     { 
//       title: "State", 
//       key: "stateId" ,
//       render: (item) =>
//         `${item?.stateId?.state}`,
//     },
//     { title: "End Date", key: "endDate" , render: (item) => item.Duration?.endDate,},
//     { title: "No of Level", key: "NoOfLevels" },
//     { title: "Total Task", key: "totalTasks" },
//     { title: "Max Tej Coins", key: "maxTejCoins" },
//     { title: "Max Lakshmi Coins", key: "maxLakshmiCoins" },
//     { title: "Completed By", key: "userCount" },
//     {
//       title: "Details",
//       key: "details",
//       render: (item) => (
//         <div
//           className={styles.viewDetails}
//           onClick={() =>
//             navigate(`/svarnamanagement/viewcampaign/${item._id}`)
//           }
//         >
//           View
//         </div>
//       ),
//     },
//     {
//       title: "Edit",
//       key: "edit",
//       render: (item) => (
//         <div
//           className={styles.viewDetails}
//           onClick={() =>
//             navigate(`/svarnamanagement/editcampaign/${item?._id}`)
//           }
//         >
//           Edit
//         </div>
//       ),
//     },




//     {
//       title: "Status",
//       key: "activeStatus",
//       render: (item) => (
//         <Popconfirm
//           title={`Are you sure to ${
//             item.activeStatus ? "deactivate" : "activate"
//           } ${item.firstName} ${item.lastName}?`}
//           onConfirm={() => handleChange(item._id, item.activeStatus , item.roleId.roleName)}
//           okText="Yes"
//           cancelText="No"
//         >
//           <Switch size="small" checked={item.activeStatus} />
//         </Popconfirm>
//       ),
//     },
//   ];

//   const customWidths = {
//     serialNumber: "8vw",
//     campaignTitle: "20vw",
//     country: "20vw",
//     state: "10vw",
//     email: "20vw",
//     endDate: "20vw",
//     NoOfLevels: "20vw",
//     totalTasks: "20vw",
//     maxTejCoins: "20vw",
//     maxLakshmiCoins: "20vw",
//     userCount: "20vw",
//     activeStatus: "20vw",
//   };

//   return (
//     <div className={styles.tableMain}>
//       <div className={styles.filterExport}>
//         {/* <p>All</p> */}
//         <div className={styles.search}>
//           <input type="text" onChange={(e) => setSearchQuery(e.target.value)} />
//         </div>
//         <div className={styles.export}>
//             <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
//           </div>
//         <div className={styles.filterDiv}>
//               <p onClick={handleFilterClick}>Filter</p>
//             </div>
//       </div>
//       <TableComponent
//             columns={columns}
//             data={campaignData}
//             loading={loading}
//             onDelete={() => {}}
//             onStatusChange={handleChange}
//             currentPage={currentPage}
//             totalPages={totalPages}
//             onNext={handleNext}
//             onPrevious={handlePrevious}
//             customWidths={customWidths}
//           />

//       {campaignData.length > 0 ? (
//           <div className={styles.footer}>
//           <div
//             className={styles.action}
//             style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
//           >
//             <button
//               className={`${styles.actionButton} ${
//                 page === 1 ? styles.disabled : styles.activeButton
//               }`}
//               onClick={handlePrevious}
//               disabled={page === 1}
//             >
//               Previous
//             </button>
//             <button
//               className={`${styles.actionButton} ${
//                 page === totalPages ? styles.disabled : styles.activeButton
//               }`}
//               onClick={handleNext}
//               disabled={page === totalPages}
//             >
//               Next
//             </button>
//           </div>
//           <div className={styles.page}>
//             <span>{currentPage}</span> <span>of</span>{" "}
//             <span>{totalPages}</span>
//           </div>
//         </div>
//       ) : (
//         <div>No data found</div>
//       )}
//        <CampaignFilter isModalOpen={isModalOpen} handleOk={handleModalOk} handleCancel={handleModalCancel} />
//     </div>
//   );
// }

// export default Campaign;
